import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";
import { convertToEncodedURL } from "assets/usefulFunctions";
import {
  APaginationEntity,
  BackendDataPageShape,
  BackendPaginationRequestObject,
} from "types";
import {
  IGetTakahReqObj,
  ITakah,
  ITakahDetail,
  TTakahFormInput,
} from "types/endpoints/sisfotu/takah";

export const takahStoreAPI = createApi({
  reducerPath: "takahStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["TakahList"],
  endpoints: (builder) => {
    return {
      getTakahPagination: builder.query<
        APaginationEntity<ITakah[]>,
        BackendPaginationRequestObject<Partial<IGetTakahReqObj>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/takah" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<ITakah[]>) =>
          response.data,
        providesTags: ["TakahList"],
      }),
      getTakahDetail: builder.query<ITakahDetail, { id: number }>({
        query: ({ id }) => {
          let url = `/takah/${id}`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<ITakahDetail>) =>
          response.data,
        providesTags: (_, __, { id }) => [
          "TakahList",
          { type: "TakahList", id },
        ],
      }),
      deleteTakah: builder.mutation<
        BackendDataShape<object>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/takah/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["TakahList"],
      }),
      createTakah: builder.mutation<
        Pick<ITakah, "id">[],
        Partial<TTakahFormInput & { issueId: number }>
      >({
        query: (data) => {
          return {
            url: "/takah",
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["TakahList"],
      }),
      updateTakah: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<TTakahFormInput & { issueId: number }>;
        }
      >({
        query: ({ id, data }) => {
          return {
            url: `/takah/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["TakahList"],
      }),
      updateTakahLetter: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<{
            letterIdsInternal: number[];
            letterIdsExternal: number[];
          }>;
        }
      >({
        query: ({ id, data }) => {
          return {
            url: `/takah/${id}/letters`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["TakahList"],
      }),
      deleteTakahLetter: builder.mutation<
        BackendDataShape<object>,
        {
          takah_id: number;
          letter_id: number;
          type: string;
        }
      >({
        query: ({ takah_id, letter_id, type }) => {
          return {
            url: `/takah/${takah_id}/letters/${letter_id}/${type}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["TakahList"],
      }),
    };
  },
});

export const {
  useGetTakahPaginationQuery,
  useGetTakahDetailQuery,
  useDeleteTakahMutation,
  useCreateTakahMutation,
  useUpdateTakahMutation,
  useUpdateTakahLetterMutation,
  useDeleteTakahLetterMutation,
  util: { resetApiState: resetTakahStoreAPI },
} = takahStoreAPI;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { UsedAPI } from "../../../api/config";
import CookieMonster from "../../../api/CookieMonster";
import { convertToEncodedURL } from "../../../assets/usefulFunctions";
import { APaginationEntity, BackendDataPageShape } from "../../../types";
import {
  BackendPaginationRequestObject,
  UserSisfotu,
} from "../../../types/endpoints/personel";
import { AGetPersonelReqObj } from "../../../types/endpoints/personel/personel";

export const userPersonelStoreAPI = createApi({
  reducerPath: "UserPersonelStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/personel`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", "Bearer " + token);
      return headers;
    },
  }),
  tagTypes: ["UserPersonelList"],
  endpoints: (builder) => {
    return {
      getUserPersonelPagination: builder.query<
        APaginationEntity<UserSisfotu[]>,
        BackendPaginationRequestObject<
          Partial<AGetPersonelReqObj & { isPenerbang: boolean }>
        >
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<UserSisfotu[]>) =>
          response.data,
        providesTags: (_, __, { id }) => [
          "UserPersonelList",
          { type: "UserPersonelList", id },
        ],
      }),
    };
  },
});
export const {
  useGetUserPersonelPaginationQuery,
  util: { resetApiState: resetUserPersonelStoreAPI },
} = userPersonelStoreAPI;

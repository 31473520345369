import { useParams } from "react-router-dom";
import { useAppSelector } from "..";

const usePersonelId = () => {
  const params = useParams<"id">();
  const user = useAppSelector((state) => state.userStore.userData);
  if (!user || !user.usertypeRole) return undefined;
  switch (user.usertypeRole) {
    case "user":
      // return user.userdetail?.id;
      /**
       * @todo refactor this return value
       */
      return user.id;
    default:
      return params.id ? parseInt(params.id) : user.userdetail?.id;
  }
};

export default usePersonelId;

import styled from "styled-components";

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6rem;
`;

export const StyledSubmitFormButtonWrapper = styled.div`
  display: inline-flex;
  gap: 16px;
  width: 100%;
  justify-content: end;
`;

export const StyledInputColContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 2px;
`;

export let UsedAPI = "";
export let PersAPI = "";
export let BasicURL = process.env.REACT_APP_ENDPOINT ?? "";
export let PersURL = process.env.REACT_APP_SISFOPERS_ENDPOINT ?? "";
// switch (process.env.REACT_APP_ENV as AENVType) {
//   case "local":
//     BasicURL = ENDPOINT_LOCAL;
//     break;
//   case "prod":
//   case "dev":
//   default:
//     BasicURL = ENDPOINT_DEV;
//     break;
// }
UsedAPI = BasicURL + "/api";
PersAPI = PersURL + "/api";

export const CredsLinks = {
  login: `${UsedAPI}/auth/login`,
  logout: `${UsedAPI}/auth/logout`,
  /**
   *
   * @param params id
   * @returns string url
   * @todo refactor this property
   */
  user: (params: string) => `${PersAPI}/user/find/${params}` as const,
} as const;

export const UsertypeLinks = {
  find: `${UsedAPI}/usertype/find`,
} as const;

export const NotificationLinks = {
  list: `${UsedAPI}/notification/list`,
} as const;

export const GatewayLinks = {
  create: `${UsedAPI}/gateway/create`,
  find: `${UsedAPI}/gateway/find`,
  update: `${UsedAPI}/gateway/update`,
  delete: `${UsedAPI}/gateway/delete`,

  all_gateways: `${UsedAPI}/report/dashboard/wika/summary`,
  current_sensor_value: `${UsedAPI}/report/dashboard/wika/sensorvalue`,
  historical_sensor_value: `${UsedAPI}/report/dashboard/wika/historicalsensorvalue`,
  device_detail: `${UsedAPI}/report/dashboard/wika/detail`,
} as const;

export const CompanyLinks = {
  create: `${UsedAPI}/company/create`,
  find: `${UsedAPI}/company/find`,
  update: `${UsedAPI}/company/update`,
  delete: `${UsedAPI}/company/delete`,
} as const;

export const LocationLinks = {
  create: `${UsedAPI}/location/create`,
  find: `${UsedAPI}/location/find`,
  update: `${UsedAPI}/location/update`,
  delete: `${UsedAPI}/location/delete`,
  showMap: (id: number) => `${UsedAPI}/location/map/show/${id}` as const,
  showMapDetail: (id: number) =>
    `${UsedAPI}/location/map/find/details?locationId=${id}` as const,
  addMap: (id: number) => `${UsedAPI}/location/map/upload/${id}` as const,
  deleteMap: (id: number) => `${UsedAPI}/location/map/delete/${id}` as const,
  sublocation1: {
    showMap: (id: number) => `${UsedAPI}/location/sub1/map/show/${id}` as const,
    addMap: (id: number) =>
      `${UsedAPI}/location/sub1/map/upload/${id}` as const,
    deleteMap: (id: number) =>
      `${UsedAPI}/location/sub1/map/delete/${id}` as const,
  },
  sublocation2: {
    addMap: (id: number) =>
      `${UsedAPI}/location/sub2/map/upload/${id}` as const,
    showMap: (id: number) => `${UsedAPI}/location/sub2/map/show/${id}` as const,
    deleteMap: (id: number) =>
      `${UsedAPI}/location/sub2/map/delete/${id}` as const,
  },
} as const;

export const DeviceLinks = {
  create: `${UsedAPI}/device/create`,
  find: `${UsedAPI}/device/find`,
  update: `${UsedAPI}/device/update`,
  delete: `${UsedAPI}/device/delete`,

  create_relation: `${UsedAPI}/device/addsensor`,
  find_relation: `${UsedAPI}/device/findrelation`,
  update_relation: `${UsedAPI}/device/updaterelation`,
  delete_relation: `${UsedAPI}/device/deleterelation`,
  create_sensor_withAlert: `${UsedAPI}/device/addsensorwithalert`,
} as const;

export const SensorLinks = {
  create: `${UsedAPI}/sensor/create`,
  find: `${UsedAPI}/sensor/find`,
  update: `${UsedAPI}/sensor/update`,
  delete: `${UsedAPI}/sensor/delete`,
} as const;

export const SensorTypeLinks = {
  create: `${UsedAPI}/sensortype/create`,
  find: `${UsedAPI}/sensortype/find`,
  update: `${UsedAPI}/sensortype/update`,
  delete: `${UsedAPI}/sensortype/delete`,
} as const;

export const AlertLinks = {
  create: `${UsedAPI}/sensor/alert/create`,
  find: `${UsedAPI}/sensor/alert/find`,
  update: `${UsedAPI}/sensor/alert/update`,
  delete: `${UsedAPI}/sensor/alert/delete`,

  find_relation: `${UsedAPI}/sensor/alert/findrelation`,
  add_relation: `${UsedAPI}/sensor/alert/addrelation`,
  remove_relation: `${UsedAPI}/sensor/alert/removerelation`,

  toggle_alert: `${UsedAPI}/sensor/alert/status`,
  toggle_email: `${UsedAPI}/sensor/alert/emailstatus`,
  toggle_wa: `${UsedAPI}/sensor/alert/whatsappstatus`,
} as const;

export const DashboardLinks = {
  usage: `${UsedAPI}/dashboard/usage`,
  sensorlog: `${UsedAPI}/dashboard/sensorlog/find`,
  enviroBoxSensorLog: `${UsedAPI}/dashboard/sensorlog/envirobox`,
} as const;

export const MachineLinks = {
  create: `${UsedAPI}/oee/machine/create`,
  find: `${UsedAPI}/oee/machine/find`,
  groupFind: `${UsedAPI}/oee/machine/group/find`,
  update: `${UsedAPI}/oee/machine/update`,
  delete: `${UsedAPI}/oee/machine/delete`,
} as const;

export const ProductLinks = {
  create: `${UsedAPI}/oee/product/create`,
  find: `${UsedAPI}/oee/product/find`,
  update: `${UsedAPI}/oee/product/update`,
  delete: `${UsedAPI}/oee/product/delete`,
} as const;

export const WorkOrderLinks = {
  create: `${UsedAPI}/oee/workorder/create`,
  find: `${UsedAPI}/oee/workorder/find`,
  update: `${UsedAPI}/oee/workorder/update`,
  delete: `${UsedAPI}/oee/workorder/delete`,
} as const;

export const ScheduleLinks = {
  create: `${UsedAPI}/schedulereport/create`,
  find: `${UsedAPI}/schedulereport/find`,
  delete: `${UsedAPI}/schedulereport/delete`,
} as const;

export const OeeLinks = {
  find_by_location: `${UsedAPI}/oee/location`,
  find_by_group_machine: `${UsedAPI}/oee/groupmachine`,
  find_by_product: `${UsedAPI}/oee/product`,
  find_by_overall: `${UsedAPI}/oee/overall`,
  find_by_shift: `${UsedAPI}/oee/shift`,
  filter_by_oee: `${UsedAPI}/oee/report/machine`,
} as const;

export const DeviceReportLinks = {
  filter_by_enviroboxLog: `${UsedAPI}/device/report/envirobox`,
  filter_by_enviroboxAlert: `${UsedAPI}/device/report/envirobox/alert`,
  filter_by_waterLevelLog: `${UsedAPI}/device/report/waterlevel`,
  filter_by_waterLevelAlert: `${UsedAPI}/device/report/waterlevel/alert`,
  filter_by_energy: `${UsedAPI}/device/report/energymeter`,
} as const;

/**
 * @todo pindahkan ini ke folder type
 */
export interface BackendDataShape<D> {
  status: string;
  data: D;
}

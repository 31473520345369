import { createUseStyles } from "react-jss";
import { BasicTheme, COLORS, mq } from "../assets/theme";
import { addAlphatoHexColor } from "../assets/usefulFunctions";

const useNotificationStyles = createUseStyles((theme: BasicTheme) => ({
  container: {
    flex: 1,
    padding: "1rem 2rem",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "100%",
  },
  titleHeading: {
    fontWeight: 600,
    margin: "0.75rem 0",
    letterSpacing: "0.25px",
    fontSize: "18px",
  },
  childrenContainer: {
    flex: 1,
    overflowY: "auto",
    overflowX: "hidden",
    margin: "0 -2rem",
    padding: "0 2rem",
  },
  navContainer: {
    margin: "-1rem -2rem",
    [mq[3]]: {
      margin: "-1rem -2rem 0 0",
    },
    zIndex: 9997,
    padding: "1.2rem",
    marginBottom: 0,
    alignItems: "center",
    // height: theme.barheight,
    flexBasis: theme.barheight,
    display: "flex",
    background: COLORS.gradient_linear_card,
    backdropFilter: "blur(5px)",
    "&::-webkit-backdrop-filter": "blur(5px)",
    fontSize: "1.5rem",
    borderBottom: "0.5px solid #7B7B7B",
    borderLeft: "0.5px solid #7B7B7B",
    "& p": {
      margin: 0,
    },
  },
  controlContainer: {
    display: "flex",
    alignItems: "center",
    color: COLORS.white,
    justifyContent: "flex-end",
    position: "relative",
  },
  actionBtn: {
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    border: 0,

    padding: ".5rem",
    marginLeft: "5px",
    marginRight: "5px",
    borderRadius: "50%",
    color: theme.black_1,
    "&:hover": {
      backgroundColor: theme.black_1,
      color: "white",
    },
  },
  logoutBtn: {
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    border: 0,
    padding: ".5rem",
    marginLeft: "5px",
    marginRight: "5px",
    borderRadius: "50%",
    color: theme.dangerColor,
    "&:hover": {
      backgroundColor: theme.dangerColor,
      color: "white",
    },
  },
  notificationBox: {
    position: "absolute",
    background: COLORS.black_1_puspenerbal,
    border: `1px solid ${COLORS.light_grey_1_puspenerbal}`,
    right: -8,
    top: 34,
    width: 256,
    [mq[3]]: {
      right: 40,
      width: 296,
    },
    zIndex: 9999,
  },
  notificationItemBox: {
    overflowY: "auto",
    maxHeight: 260,
    padding: "4px",
    zIndex: 999,
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      border: "3.6px solid transparent",
    },
  },
  notificationItem: {
    padding: "11.5px 14px",
    background: COLORS.light_brown_1_puspenerbal,
    transition: "all .2s",
    marginBottom: 8,
    "&:hover": {
      background: addAlphatoHexColor(COLORS.blue_1_puspenerbal, 0.38),
    },
    "&.selected": {
      background: addAlphatoHexColor(COLORS.blue_1_puspenerbal, 0.38),
      borderRight: `4px solid ${COLORS.blue_1_puspenerbal}`,
    },
    "&.selected.add": {
      background: addAlphatoHexColor(COLORS.green_1_puspenerbal, 0.38),
      borderRight: `4px solid ${COLORS.green_1_puspenerbal}`,
    },
    "&.selected.delete": {
      background: addAlphatoHexColor(COLORS.red_1_puspenerbal, 0.38),
      borderRight: `4px solid ${COLORS.red_1_puspenerbal}`,
    },
    "&.selected.modified": {
      background: addAlphatoHexColor(COLORS.yellow_puspenerbal_1, 0.38),
      borderRight: `4px solid ${COLORS.yellow_puspenerbal_1}`,
    },
    "&.add": {
      background: addAlphatoHexColor(COLORS.green_1_puspenerbal, 0.2),
    },
    "&.delete": {
      background: addAlphatoHexColor(COLORS.red_1_puspenerbal, 0.2),
    },
    "&.modified": {
      background: addAlphatoHexColor(COLORS.yellow_puspenerbal_1, 0.2),
    },
    "&.unread": {
      background: COLORS.light_darkbrown_1_puspenerbal,
      borderRight: `4px solid ${COLORS.red_1_puspenerbal}`,
      "&:hover": {
        background: COLORS.light_darkbrown_1_puspenerbal,
      },
    },
  },
  notificationItemText: {
    "& div": {
      fontSize: 11,
      fontWeight: 400,
      marginBottom: 8,
    },
    "& p": {
      fontSize: 9,
      fontWeight: 600,
      margin: 0,
    },

    color: COLORS.white,
  },
  notificationTime: {
    fontSize: "8px",
    textAlign: "right",
  },
  notificationItemWarning: {
    fontWeight: 600,
    color: COLORS.yellow,
    margin: "0 5px",
  },
  notificationIteDanger: {
    fontWeight: 600,
    color: COLORS.red,
    margin: "0 5px",
  },
  viewAllButton: {
    width: "100%",
    padding: "8px 0",
    textAlign: "center",
    position: "sticky",
    bottom: 0,
    cursor: "pointer",
    color: COLORS.white,
    backgroundColor: COLORS.light_darkbrown_1_puspenerbal,
    fontSize: 11,
    fontWeight: 400,
  },
}));

export default useNotificationStyles;

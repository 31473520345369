import { FC, Fragment } from "react";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import { MdClose } from "react-icons/md";
import styled from "styled-components";

import { COLORS, mq } from "assets/theme";

import { easing } from "config/transitionProps";

import { ModalProps } from "components/Modal/CustomModal";
import { CloseButton, Wrapper } from "components/Modal/utils/modal.style";

export interface PuspenerbalModalProps extends ModalProps {
  showLineSeparator?: boolean;
}

const HeaderText = styled.div`
  color: white;
  align-self: center;
  font-weight: bold;
  letter-spacing: 0.02em;
  font-size: 15px;
`;

const Header = styled.div`
  border-radius: 8px 8px 0 0;
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  color: white;
  @media print {
    display: none;
  }
`;

export const StyledPuspenerbalModal = styled.div`
  z-index: 100;
  background: #35363a;
  position: relative;
  padding: 2rem;
  margin: auto;
  border: 1px solid ${COLORS.light_grey_3_puspenerbal};
  width: 42rem;
  ${mq[3]} {
    min-width: 48rem;
    max-width: 50%;
  }
  max-height: 100vh;
`;

export const LineSeparator = styled.div`
  border-bottom: 1px solid white;
  margin-bottom: 24px;
  margin-right: 8px;
`;

export const StyledModalContent = styled.div`
  max-height: 70vh;
  /* overflow-y: auto; */
  overflow-x: hidden;
  /* custom scrollbar */
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #8997a9;
    border: 3px solid transparent;
    background-clip: content-box;
  }
`;

export const PuspenerbalModal: FC<PuspenerbalModalProps> = ({
  isShown,
  isDelete,
  isEdit,
  hide,
  modalContent,
  headerText,
  withCloseIcon = true,
  showLineSeparator,
}: PuspenerbalModalProps) => {
  const rendering = isShown || isDelete || isEdit;
  if (!rendering) return null;

  const modal = (
    <Fragment>
      <motion.div
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          background: "rgba(0, 0, 0, 0.3)",
          zIndex: 9998,
        }}
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: { duration: 0.6, ease: easing },
        }}
        onClick={hide}
        exit={{ opacity: 0 }}
      />
      <Wrapper>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.6, ease: easing },
          }}
          exit={{ opacity: 0, y: 100 }}
        >
          <StyledPuspenerbalModal>
            <div>
              <Header>
                <HeaderText>{headerText}</HeaderText>
                {withCloseIcon && (
                  <CloseButton onClick={hide}>
                    <MdClose size={20} color={COLORS.white} />
                  </CloseButton>
                )}
              </Header>
              {showLineSeparator && <LineSeparator />}
            </div>
            <div>{modalContent}</div>
          </StyledPuspenerbalModal>
        </motion.div>
      </Wrapper>
    </Fragment>
  );

  return ReactDOM.createPortal(modal, document.getElementById("portal")!);
  //   return (
  //     <Fragment>
  //     </Fragment>
  //   )
};

import { FunctionComponent, useMemo } from "react";
import { Navigate } from "react-router-dom";

import { KnownUsertypeName } from "api/credentialsAPI/usertype";

import { checkIfUndefined } from "assets/usefulFunctions";

import { useUsertypeName } from "hooks/auth/useUsertype";
import { SisfoTURoutes } from "config/nav";

interface Props {}

const Redirector: FunctionComponent<Props> = () => {
  const usertypeName = useUsertypeName();
  const to = getDefaultRoute(usertypeName);

  if (checkIfUndefined(usertypeName)) {
    return <>Loading...</>;
  }
  return <Navigate replace to={to} />;
};

export default Redirector;

export const getDefaultRoute = (usertypeLogin?: KnownUsertypeName) => {
  switch (usertypeLogin) {
    case "personel":
    case "kasubsiJasrek":
    case "kasiWatpers":
    case "kasiBintal":
    case "kasiKum":
    case "kasubsiEvadal":
    case "kasiProfesiPenerbang":
    case "kasubditWatpers":
    case "kasubsiUrikes":
    case "kasiKesud":
    case "kasiMinkes":
    case "kasubditKes":
    case "kasubsiDalpersmil":
    case "kasiDalpersmil":
    case "kasiDalperssip":
    case "kasubsiDatapers":
    case "kasiMindik":
    case "kasubditMinpers":
      return SisfoTURoutes.dashboard;
    case "superadmin":
      return SisfoTURoutes.managementAdminAccount;
    default:
      return SisfoTURoutes.dashboard;
  }
};

export const useDefaultRoute = () => {
  const usertypeName = useUsertypeName();
  return useMemo(() => {
    return getDefaultRoute(usertypeName);
  }, [usertypeName]);
};

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { PersAPI } from "api/config";
import CookieMonster from "api/CookieMonster";
import { convertToEncodedURL } from "assets/usefulFunctions";
import {
  APaginationEntity,
  BackendDataPageShape,
  BackendPaginationRequestObject,
} from "types";
import {
  IGetPositionReqObj,
  IPosition,
} from "types/endpoints/sisfopers/position";

export const positionStoreAPI = createApi({
  reducerPath: "positionStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${PersAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["PositionList"],
  endpoints: (builder) => {
    return {
      getPositionPagination: builder.query<
        APaginationEntity<IPosition[]>,
        BackendPaginationRequestObject<Partial<IGetPositionReqObj>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/jabatan" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<IPosition[]>) =>
          response.data,
        providesTags: ["PositionList"],
      }),
    };
  },
});

export const {
  useGetPositionPaginationQuery,
  util: { resetApiState: resetPositionStoreAPI },
} = positionStoreAPI;

import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router";
import CookieMonster from "../../api/CookieMonster";
import { ListOfRoutes } from "../../config/nav";

interface Props {
  children: ReactNode;
}

const AuthHOC: React.FunctionComponent<Props> = ({ children }) => {
  let location = useLocation();
  let jwt = CookieMonster.loadCookie("jwt");
  if (jwt && jwt.length) return <>{children}</>;

  return <Navigate to={ListOfRoutes.loginpage} state={{ from: location }} />;
};

export default AuthHOC;

import { UsedAPI } from "api/config";

export const ListOfRoutes = {
  homepage: "/",
  loginpage: "/login",
  logout: "/logout",
  draftPage: "/draft",
  inboxpage: "/inbox",
  outboxpage: "/outbox",
  dashboard: "/dashboard",
  management: "/management",
  esignature: "/e-signature",
  reports: "/reports",
  schedule: "/schedule",
  verify: "/verification/success",
  machineDetails: "/machine",
  profile: "/profile",
} as const;

export const ReportLinks = {
  filter_by_caseLog: (params: string) => `${UsedAPI}/case${params}` as const,
} as const;

export const DashboardPuspenerbalLinks = {
  get dashboard_by_dsp(): string {
    return `${UsedAPI}/dashboard/rekap_dsp` as const;
  },

  get dashboard_by_aktifitas_penerbangan(): string {
    return `${UsedAPI}/dashboard/aktivitas_penerbangan` as const;
  },
} as const;

export class SisfoTURoutes {
  static get base(): string {
    return `/` as const;
  }

  static get login(): string {
    return `${this.base}login` as const;
  }

  static get dashboard(): string {
    return `${this.base}dashboard` as const;
  }

  static get notification(): string {
    return `${this.base}notification` as const;
  }

  static get reports(): string {
    return `${this.base}reports` as const;
  }

  static get logout(): string {
    return `${this.base}logout` as const;
  }

  static get profile(): string {
    return `${this.base}profile` as const;
  }

  static get inbox(): string {
    return `${this.base}inbox` as const;
  }
  static get outbox(): string {
    return `${this.base}outbox` as const;
  }
  static get operational(): string {
    return `${this.base}operational` as const;
  }
  static get template(): string {
    return `${this.base}letter-template` as const;
  }
  static get outboxReport(): string {
    return `${this.base}report/outbox` as const;
  }
  static get inboxReport(): string {
    return `${this.base}report/inbox` as const;
  }
  static getInboxDetail(id?: string): string {
    return `${this.inbox}/detail/${id}` as const;
  }
  static getOutboxDetail(id?: string): string {
    return `${this.outbox}/detail/${id}` as const;
  }
  static get backupRestore(): string {
    return `/backup-restore` as const;
  }
  static get backupRestoreOutbox(): string {
    return `${this.backupRestore}/outbox` as const;
  }
  static get backupRestoreInbox(): string {
    return `${this.backupRestore}/inbox` as const;
  }
  static get backup(): string {
    return `${this.backupRestore}/backup` as const;
  }
  static get restore(): string {
    return `${this.backupRestore}/restore` as const;
  }
  static get draft(): string {
    return `${this.base}draft` as const;
  }
  static get workFlow(): string {
    return `${this.base}workflow` as const;
  }
  static get report(): string {
    return `${this.base}laporan` as const;
  }
  static get category(): string {
    return `${this.base}category` as const;
  }
  static get esignature(): string {
    return `${this.base}e-signature` as const;
  }

  static get takah(): string {
    return `${this.base}takah` as const;
  }
  static getTakahDetail(id?: string): string {
    return `${this.takah}/${id}` as const;
  }

  static get account(): string {
    return `${this.base}account` as const;
  }

  static get account_changePwd(): string {
    return `${this.account}/change-password` as const;
  }

  static get account_getOTP(): string {
    return `${this.account}/otp` as const;
  }
  static get draft_detail(): string {
    return `${this.draft}/detail` as const;
  }

  static get management(): string {
    return `${this.base}management` as const;
  }

  static get managementAdminAccount(): string {
    return `${this.management}/account` as const;
  }
  static get managementAdminRole(): string {
    return `${this.management}/role` as const;
  }

  static getDraftDetail(id: string): string {
    return `${this.draft_detail}/${id}` as const;
  }
}

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

import { convertToEncodedURL } from "assets/usefulFunctions";

import {
  IRole,
  IRolePatch,
  IRoleQueryParameter,
} from "types/endpoints/sisfotu/role-permission";

export const roleStoreAPI = createApi({
  reducerPath: "roleStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/role`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["RoleList", "RoleLovList"],
  endpoints: (builder) => {
    return {
      getRoles: builder.query<IRole[], Partial<IRoleQueryParameter>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<IRole[]>) =>
          response.data,
        providesTags: ["RoleList"],
      }),
      getRoleLov: builder.query<string[], Partial<object>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/lov" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<string[]>) =>
          response.data,
        providesTags: ["RoleLovList"],
      }),
      updateRole: builder.mutation<BackendDataShape<object>, IRolePatch>({
        query: ({ id, ...rest }) => {
          return {
            url: `/${id}`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["RoleList"],
      }),
      deleteRole: builder.mutation<BackendDataShape<object>, { id: number }>({
        query: ({ id }) => {
          return {
            url: `/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["RoleList"],
      }),
    };
  },
});
export const {
  useGetRolesQuery,
  useGetRoleLovQuery,
  useDeleteRoleMutation,
  useUpdateRoleMutation,
  util: { resetApiState: resetRoleStoreAPI },
} = roleStoreAPI;

import { CSSProperties } from "react";
import Select, {
  Props as SelectProps,
  components,
  GroupBase,
} from "react-select";
import { Label } from "reactstrap";

import { COLORS } from "assets/theme";
import { backgroundVariantColorSwitch } from "assets/usefulFunctions";

import {
  InputVariant,
  useInputTextStyle,
} from "components/InputForm/InputText";

function PureInputSelectPuspenerbal<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  noOptionsMessage,
  styles,
  placeholder,
  variant,
  label,
  isError,
  hideBorderOption,
  hideIndicatorSeparator,
  containerStyle,
  controlStyle,
  multiValueStyle,
  valueContainerStyle,
  required,
  ...rest
}: SelectProps<Option, IsMulti, Group> & {
  variant?: InputVariant;
  label?: string;
  required?: boolean;
  isError?: boolean;
  hideBorderOption?: boolean;
  hideIndicatorSeparator?: boolean;
  containerStyle?: CSSProperties;
  controlStyle?: CSSProperties;
  multiValueStyle?: CSSProperties;
  valueContainerStyle?: CSSProperties;
}) {
  const inputTextClasses = useInputTextStyle({ theme: { variant } });
  return (
    <>
      <div style={{ marginBottom: 16, padding: 0, ...containerStyle }}>
        {label && (
          <Label
            className={[
              isError ? inputTextClasses.errorText : inputTextClasses.labelText,
            ].join(" ")}
          >
            {label}
          </Label>
        )}

        {required ? <span className={inputTextClasses.errorText}>*</span> : ""}
        <Select
          placeholder={placeholder ? placeholder : "Select..."}
          components={{
            IndicatorSeparator: hideIndicatorSeparator
              ? () => null
              : components.IndicatorSeparator,
          }}
          noOptionsMessage={
            noOptionsMessage
              ? noOptionsMessage
              : ({ inputValue }) => {
                  if (inputValue) return `'${inputValue}' not found!`;
                  return "No Option!";
                }
          }
          required={required}
          styles={{
            control: (curStyle, { isDisabled }) => {
              return {
                ...curStyle,
                minHeight: 36,
                cursor: isDisabled ? "not-allowed" : "unset",
                background: isError
                  ? COLORS.gradient_red_error_puspenerbal
                  : backgroundVariantColorSwitch(variant, isDisabled),
                borderRadius: 0,
                border: isError
                  ? `1px solid ${COLORS.red_1_puspenerbal}`
                  : hideBorderOption
                  ? "none"
                  : variant === "dark"
                  ? `1px solid ${COLORS.light_grey_3_puspenerbal}`
                  : "none",
                backdropFilter: "blur(5px)",
                boxShadow: "none",
                color: "white",

                ...controlStyle,
              };
            },
            valueContainer: (base) => ({
              ...base,
              ...valueContainerStyle,
            }),
            placeholder: (styles) => ({
              ...styles,
              color: COLORS.dark_grey_1,
              fontSize: "1.2em",
              whiteSpace: "nowrap",
            }),
            input: (curStyle) => ({
              ...curStyle,
              color: "#FFFFFF",
              fontSize: "1.2em",
            }),
            singleValue: (styles) => ({
              ...styles,
              color: "#FFFFFF",
              fontSize: "1.2em",
            }),
            multiValue: (styles) => {
              return {
                ...styles,
                ...multiValueStyle,
                backgroundColor: "#18A0FB",
                borderRadius: "16px",
                padding: "4px 8px",
                color: "white",
              };
            },
            multiValueLabel: (styles) => ({
              ...styles,
              color: "#FFFFFF",
              fontSize: "1.2em",
            }),
            option: (styles, { isDisabled, isFocused }) => {
              return {
                ...styles,
                color: isFocused ? "#fff" : "#fff",
                background: isError
                  ? COLORS.gradient_red_error_puspenerbal
                  : backgroundVariantColorSwitch(variant, isDisabled),
                fontSize: "1.2em",
              };
            },
            dropdownIndicator: (styles) => ({
              ...styles,
              padding: 0,
              marginLeft: "5px",
              marginRight: "5px",
            }),
            multiValueRemove: (styles) => ({
              ...styles,
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent",
                "& svg": {
                  fill: COLORS.brown_1_puspenerbal,
                },
              },
            }),
            noOptionsMessage: (styles) => ({
              ...styles,
              color: "red",
            }),
            menu: (styles) => ({
              ...styles,
              borderRadius: 0,
              marginTop: "5px",
              zIndex: 99999,
              backgroundColor:
                variant === "light"
                  ? COLORS.light_brown_1_puspenerbal
                  : COLORS.black_1_puspenerbal,
            }),
            menuList: (styles) => ({
              ...styles,
              padding: 0,
              backgroundColor:
                variant === "light"
                  ? COLORS.light_brown_1_puspenerbal
                  : COLORS.black_1_puspenerbal,
            }),
            container: (styles) => ({
              ...styles,
              width: "fill-available",
            }),
            ...styles,
          }}
          {...rest}
        />
        {rest["aria-errormessage"] ? (
          isError ? (
            <p className={inputTextClasses.errorText}>
              {rest["aria-errormessage"]}
            </p>
          ) : (
            <p className={inputTextClasses.helperText}>
              {rest["aria-errormessage"]}
            </p>
          )
        ) : null}
        {/* <p className={classesAction.requiredText}>
          {rest["aria-errormessage"]}
        </p> */}
      </div>
    </>
  );
}

export default PureInputSelectPuspenerbal;

import cookie from "react-cookies";
import { CookieSerializeOptions } from "cookie";

type UserCredCookies =
  | "jwt"
  | "user_id"
  | "id"
  | "usertypeRole"
  | "companyId"
  | "username"
  | "usertypeId"
  | "userDetailId"
  | "usertypeName";

type UserAdditionalCookies = "jwt_change_password";

type CookieName = UserCredCookies | UserAdditionalCookies;

type CookeDataType = string | number | object;

type CookieObject = Partial<Record<CookieName, CookeDataType>>;

export type CookieDataArray = [CookieName, CookeDataType];

const defaultCookieConfig: CookieSerializeOptions = {
  path: "/",
  sameSite: true,
};

export default class CookieMonster {
  /**
   *
   * @param cookieName name of the Cookie
   * @returns `string`
   */
  static loadCookie(cookieName: CookieName) {
    return cookie.load(cookieName);
  }

  /**
   *
   * @param cookieName name of the Cookie
   * @param data data you want to put in
   * @param options optional options you want to put in cookie
   */
  static saveCookie(
    cookieName: CookieName,
    data: CookeDataType,
    options?: CookieSerializeOptions,
  ) {
    cookie.save(cookieName, data, {
      ...defaultCookieConfig,
      ...options,
    });
  }
  /**
   *
   * @param cookieName cookie name to be removed
   */
  static removeCookie(cookieName: CookieName) {
    cookie.remove(cookieName, {
      ...defaultCookieConfig,
    });
  }
  /**
   * Eat them all! OMNOMNOMNOMNOM!
   * @param cookieNames all cookie names you want to eat (in Array)
   * @example `['menu','permission']`
   */
  static removeAllTheseCookies(cookieNames: CookieName[]) {
    cookieNames.forEach((name) => {
      cookie.remove(name, { ...defaultCookieConfig });
    });
  }
  /**
   *
   * @param cookieNames cookie names to load (in Array)
   * @returns `CookieObject`
   */
  static loadAllTheseCookies(cookieNames: CookieName[]) {
    let data: CookieObject = {};
    cookieNames.forEach((name) => {
      data[name] = cookie.load(name);
    });
    return data;
  }

  /**
   * Save all provided cookie names with option `{path:'/'}`
   * @param cookieDatas Array of `[cookieName, data]`
   * @example [ ['menu','simelekete'], ['division_id', 2]]
   */
  static saveAllTheseCookies(
    cookieDatas: {
      [key in UserCredCookies]?: CookeDataType;
    },
    options?: CookieSerializeOptions,
  ) {
    for (let key in cookieDatas) {
      let k = key as UserCredCookies;
      let data = cookieDatas[k];
      if (data) {
        cookie.save(key, data, { ...defaultCookieConfig, ...options });
      }
    }
  }
}

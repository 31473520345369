import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";
import { IIssue } from "types/endpoints/sisfotu/issue";

export const issueStoreAPI = createApi({
  reducerPath: "issueStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["MainIssueList", "IssueList"],
  endpoints: (builder) => {
    return {
      getMainIssue: builder.query<IIssue[], object>({
        query: () => {
          let url = "/issue";
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<IIssue[]>) =>
          response.data,
        providesTags: ["MainIssueList"],
      }),
      getIssue: builder.query<IIssue[], { id: number }>({
        query: ({ id }) => {
          let url = `/issue/${id}/child`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<IIssue[]>) =>
          response.data,
        providesTags: ["IssueList"],
      }),
    };
  },
});

export const {
  useGetMainIssueQuery,
  useGetIssueQuery,
  util: { resetApiState: resetIssueStoreAPI },
} = issueStoreAPI;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

import {
  convertToEncodedURL,
  generateFormDataComplexData,
} from "assets/usefulFunctions";

import { APaginationEntity, BackendDataPageShape } from "types";
import { BackendPaginationRequestObject } from "types/endpoints/personel";
import {
  ALetterResponse,
  ALetterRequest,
} from "types/endpoints/sisfotu/letter";

export const letterStoreAPI = createApi({
  reducerPath: "letterStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/letters`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["LetterList"],
  endpoints: (builder) => {
    return {
      getInbox: builder.query<ALetterResponse[], Partial<ALetterResponse>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/in" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<ALetterResponse[]>) =>
          response.data,
        providesTags: ["LetterList"],
      }),

      getAllLetter: builder.query<
        APaginationEntity<ALetterResponse[]>,
        BackendPaginationRequestObject<Partial<ALetterResponse>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataPageShape<ALetterResponse[]>,
        ) => response.data,
        providesTags: ["LetterList"],
      }),

      getOutbox: builder.query<ALetterResponse[], Partial<ALetterResponse>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/out" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<ALetterResponse[]>) =>
          response.data,
        providesTags: ["LetterList"],
      }),

      getDraft: builder.query<
        APaginationEntity<ALetterResponse[]>,
        BackendPaginationRequestObject<Partial<ALetterResponse>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/draft" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataPageShape<ALetterResponse[]>,
        ) => response.data,
        providesTags: ["LetterList"],
      }),
      getLetterById: builder.query<ALetterResponse, Partial<ALetterResponse>>({
        query: ({ id }) => {
          // if (!id) return
          let url = `/${id}`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<ALetterResponse>) =>
          response.data,
        providesTags: ["LetterList"],
      }),
      createLetter: builder.mutation<
        BackendDataShape<object>,
        Partial<Omit<ALetterRequest, "id">> & {
          /**
           * @description Create a new letter, if `false` then it sends to the receivers
           * and if it's a `true` value then the letter will be saved to `draft`
           * @default false
           * @todo Refactor still need to follow-up BE for this path url
           */
          isDraft?: boolean;
        }
      >({
        query: ({ isDraft, ...rest }) => {
          return {
            url: isDraft ? "?draft=draft" : "",
            body: rest,
            method: "POST",
          };
        },
        invalidatesTags: ["LetterList"],
      }),
      updateLetter: builder.mutation<
        BackendDataShape<object>,
        Omit<ALetterRequest, "id"> & {
          id: number;
        }
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/${id}/draf?publish=publish" : ""}`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["LetterList"],
      }),
      deleteLetter: builder.mutation<BackendDataShape<object>, { id: number }>({
        query: ({ id }) => {
          return {
            url: `/${id}/draft`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["LetterList"],
      }),

      restoreLetter: builder.mutation<BackendDataShape<object>, { file: File }>(
        {
          query: ({ ...rest }) => {
            return {
              url: "/restore",
              body: generateFormDataComplexData(rest),
              method: "POST",
            };
          },
          invalidatesTags: ["LetterList"],
        },
      ),
    };
  },
});
export const {
  useRestoreLetterMutation,
  useGetAllLetterQuery,
  useGetInboxQuery,
  useGetDraftQuery,
  useGetLetterByIdQuery,
  useGetOutboxQuery,
  useCreateLetterMutation,
  useUpdateLetterMutation,
  useDeleteLetterMutation,
  util: { resetApiState: resetLetterStoreAPI },
} = letterStoreAPI;

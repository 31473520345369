import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

import {
  convertToEncodedURL,
  generateFormDataComplexData,
} from "assets/usefulFunctions";

import { ASignature } from "types/endpoints/sisfotu/esignature";

export const esignatureStoreAPI = createApi({
  reducerPath: "esignatureStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/signatures`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["SignatureList"],
  endpoints: (builder) => {
    return {
      getSignatures: builder.query<ASignature[], Partial<ASignature>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<ASignature[]>) =>
          response.data,
        providesTags: ["SignatureList"],
      }),
      getSignaturesById: builder.query<ASignature, Partial<ASignature>>({
        query: ({ id }) => {
          let url = `/${id}`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: any) => response,
        providesTags: ["SignatureList"],
      }),
      createSignatures: builder.mutation<
        BackendDataShape<object>,
        Partial<Omit<ASignature, "id">> & {}
      >({
        query: ({ ...rest }) => {
          return {
            url: "",
            body: generateFormDataComplexData(rest),
            method: "POST",
          };
        },
        invalidatesTags: ["SignatureList"],
      }),

      updateSignatures: builder.mutation<
        BackendDataShape<object>,
        Partial<ASignature>
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/${id}`,
            body: generateFormDataComplexData(rest),
            method: "PATCH",
          };
        },
        invalidatesTags: ["SignatureList"],
      }),
      deleteSignatures: builder.mutation<
        BackendDataShape<object>,
        { id: number }
      >({
        query: ({ id }) => {
          return {
            url: `/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["SignatureList"],
      }),

      restoreSignatures: builder.mutation<
        BackendDataShape<object>,
        { file: File }
      >({
        query: ({ ...rest }) => {
          return {
            url: "/restore",
            body: generateFormDataComplexData(rest),
            method: "POST",
          };
        },
        invalidatesTags: ["SignatureList"],
      }),
    };
  },
});
export const {
  useCreateSignaturesMutation,
  useDeleteSignaturesMutation,
  useGetSignaturesQuery,
  useGetSignaturesByIdQuery,
  useUpdateSignaturesMutation,
  useRestoreSignaturesMutation,
  util: { resetApiState: resetStoreAPI },
} = esignatureStoreAPI;

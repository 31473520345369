import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";
import { ANotification } from "api/notificationAPI";

import { convertToEncodedURL } from "assets/usefulFunctions";

export const notificationStoreAPI = createApi({
  reducerPath: "notificationAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/notifications`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["NotificationList"],
  endpoints: (builder) => {
    return {
      getListNotification: builder.query<ANotification[], object>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          const url = params;
          return {
            url,
            method: "GET",
          };
        },
        providesTags: ["NotificationList"],
        transformResponse: (response: BackendDataShape<ANotification[]>) => {
          return response.data;
        },
      }),
    };
  },
});

export const {
  useGetListNotificationQuery,
  util: { resetApiState: resetNotificationAPI },
} = notificationStoreAPI;

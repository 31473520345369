import { Dispatch, FormEvent, SetStateAction } from "react";
import { AxiosResponse } from "axios";
import * as XLSX from "xlsx";
import jsPDF, { jsPDFOrientations, jsPDFUnits } from "jspdf";
import autoTable from "jspdf-autotable";
import { format, parse } from "date-fns";
import { id } from "date-fns/locale";

import { COLORS } from "assets/theme";
import createCSVFile, { CSVProps } from "assets/usefulFunctions/createCSVFile";

import { PermissionDataType, UserDataWOToken } from "api/credentialsAPI";
import { BackendDataShape } from "api/config";

import { InputVariant } from "components/InputForm/InputText";

import { NRPOptsType } from "hooks/management/usePersonelOpts";

import {
  DataTypeUserAdditionalType,
  UriMindik,
  UriNameUserAdditionalType,
  UserSisfotu,
} from "types/endpoints/personel";
import { APersonelApproval } from "types/endpoints/personel/approval";
import { BasicSelectOpt } from "types";

export type ValueInterval = `${number}${"m" | "h" | "d" | "w" | "mo" | "y"}`;

export function convertDashTextToProper(str: string): string {
  let strArr = str.split("_");
  return strArr
    .map((val) => {
      let [a, ...rest] = val.split("");
      return `${a.toUpperCase()}${rest.map((a) => a).join("")}`;
    })
    .join(" ");
}

export const checkIfKeyExistInObject = <T extends object>(
  key: keyof T,
  object: T,
): boolean => {
  if (!object) return false;
  const keySelected = Object.keys(object).some((item) => item === key);
  return !!keySelected;
};

/**
 * @description function that can filters out NaN and undefined values
 * from an object in TypeScript with generic type checking
 * @param obj
 * @returns
 */
export const filterNaNAndUndefined = <T extends { [key: string]: any }>(
  obj: T,
): T => {
  const filteredObj: { [key: string]: any } = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      // Check for `NaN` or `undefined` or `empty string`
      if (value !== undefined && value !== "" && !Number.isNaN(value)) {
        filteredObj[key] = value;
      }
    }
  }
  //casting filtered object
  return filteredObj as T;
};

export const checkIsDateValid = (date: Date | string): boolean => {
  return !Number.isNaN(new Date(date).getTime());
};

export const checkIfKeyExistInApproval = <T extends object>(
  key: keyof T,
  selectedApproval: APersonelApproval<T>,
): boolean => {
  if (!selectedApproval?.data?.data) return false;
  switch (selectedApproval.dataType) {
    // case "pictureProfile":
    // case "dalpersBaseAddress":
    // case "dalpersBase": {
    //   const keySelected = Object.keys(selectedApproval.data).find(
    //     (item) => item === key,
    //   );
    //   return !!keySelected;
    // }
    default: {
      const keySelected = Object.keys(selectedApproval.data.data).find(
        (item) => item === key,
      );
      return !!keySelected;
    }
  }
};

export const getDataTypeFromUriname = (
  uriname: UriNameUserAdditionalType,
): DataTypeUserAdditionalType => {
  switch (uriname) {
    case "bahasaasing":
      return "bahasaAsing";
    case "bahasadaerah":
      return "bahasaDaerah";
    case "datakeluarga":
      return "dataKeluarga";
    case "garjas":
      return "garjas";
    case "kasus":
      return "kasus";
    case "pendidikanmiliter":
      return "pendidikanMiliter";
    case "dikum":
      return "pendidikanUmum";
    case "dikbangspes":
      return "dikbangspes";
    case "dikma":
      return "dikma";
    case "dikprof":
      return "dikprof";
    case "penugasan":
      return "penugasan";
    case "profesinonpenerbang":
      return "profesiNonPenerbang";
    case "profesipenerbang":
      return "profesiPenerbang";
    case "riwayatjabatan":
      return "riwayatJabatan";
    case "riwayatpangkat":
      return "riwayatPangkat";
    case "stakes":
      return "stakes";
    case "dataUmum":
      return "dataUmum";
    case "tandajasa":
      return "tandaJasa";
  }
};

export const getMilitaryEduDatatypeFromUriname = (
  uriname: UriMindik | UriNameUserAdditionalType | undefined,
): UriMindik => {
  switch (uriname) {
    case "dikbangspes":
      return "dikbangspes";
    case "dikma":
      return "dikma";
    case "dikprof":
      return "dikprof";
    default:
      return "dikbangspes";
  }
};

export const backgroundVariantColorSwitch = (
  variant?: InputVariant,
  disabled?: boolean,
): string => {
  if (variant) {
    switch (variant) {
      case "danger":
        return addAlphatoHexColor(COLORS.red_1_puspenerbal, 0.3);
      case "warning":
        return addAlphatoHexColor(COLORS.yellow_puspenerbal_1, 0.3);
      case "success":
        return addAlphatoHexColor(COLORS.green_1_puspenerbal, 0.3);
      case "dark":
        return COLORS.black_1_puspenerbal;
      case "light":
      default:
        if (disabled) {
          return COLORS.light_brown_1_puspenerbal;
        } else {
          return COLORS.black_1_puspenerbal;
        }
    }
  } else {
    if (disabled) {
      return COLORS.light_brown_1_puspenerbal;
    } else {
      return COLORS.black_1_puspenerbal;
    }
  }
};

export const filterObjectUndefinedValue = <T extends object>(data: T): T => {
  const filteredEntries = Object.entries(data).filter(
    // @typescript-eslint/no-unused-vars
    ([_, value]) => value !== undefined,
  );
  const filteredObject = Object.assign(
    {} as T,
    ...filteredEntries.map(([k, v]) => ({ [k]: v })),
  );
  return filteredObject;
};
export const isUserAllowedFormsByPermission = (
  user: UserDataWOToken | undefined,
  permission: PermissionDataType,
) => {
  if (user) {
    if (user.usertype.role === "user") {
      return true;
    } else {
      const allowedForms = user.usertype.permissionData?.find((item) => {
        return item === permission;
      });
      return !!allowedForms;
    }
  }
  return false;
};

export const replaceInputTextToUppercase = (e: FormEvent<HTMLInputElement>) => {
  e.currentTarget.value = "" + e.currentTarget.value.toUpperCase();
  return e.currentTarget.value;
};
export const replaceInputTextToNumberOnly = (
  e: FormEvent<HTMLInputElement>,
) => {
  e.currentTarget.value = "" + e.currentTarget.value.replace(/\D/g, "");
  return e.currentTarget.value;
};

export const numberWithDots = (num: number) =>
  num
    .toFixed()
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
export const numberWithCommas = (num: number) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

export type ChartAcceptableTimeUnit =
  | "millisecond"
  | "second"
  | "minute"
  | "hour"
  | "day"
  | "week"
  | "month"
  | "quarter"
  | "year";

export const convertResponseImageUrlState = (
  setState: Dispatch<SetStateAction<string>>,
  res: AxiosResponse<any, any>,
) => {
  let image = btoa(
    new Uint8Array(res.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      "",
    ),
  );
  setState(`data:${res.headers["content-type"].toLowerCase()};base64,${image}`);
};

export const capitalizeWords = (arrString: string[]) => {
  return arrString.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
};

export const convertAxiosResponseToString = (
  res: AxiosResponse<any, any>,
): string => {
  let image = btoa(
    new Uint8Array(res.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      "",
    ),
  );
  return `data:${res.headers["content-type"].toLowerCase()};base64,${image}`;
};

export const newArrayWithUniqueValueSameKey = <T, K extends keyof T>(
  key: K,
  array: T[],
) => {
  const arrayUniqueByKey = [
    ...new Map(array.map((item) => [item[key], item])).values(),
  ];
  return arrayUniqueByKey;
};

export function extractTimeFormat(
  interval: ValueInterval,
): ChartAcceptableTimeUnit {
  let text = interval.match(/([a-z]+)/);
  if (text) {
    let time = text[0];
    switch (time) {
      case "m":
        return "minute";
      case "h":
        return "hour";
      case "d":
        return "day";
      case "w":
        return "week";
      case "mo":
        return "month";
      case "y":
        return "year";
      default:
        throw new Error("invalid interval passed!");
    }
  } else {
    throw new Error("invalid interval passed!");
  }
}

export const idrCurrencyFormatter = new Intl.NumberFormat("id-ID", {
  style: "currency",
  currency: "IDR",
});

export const concatPerwiraLabelInNrp = (
  nrp: string,
  rankCategoryGeneral: string,
): string => {
  if (!rankCategoryGeneral) return "";
  if (rankCategoryGeneral.toUpperCase() === "PERWIRA") {
    return `${nrp}/P`;
  } else {
    return nrp;
  }
};

export const idNumberFormatter = new Intl.NumberFormat("id-ID", {
  // style
});

/**
 *
 * @param number the number to round
 * @param n decimal point to round
 */
export function roundNumber(number: number, n: number) {
  if (n < 0) return number;
  let rounding10 = Math.pow(10, n);
  return Math.round(number * rounding10) / rounding10;
}

const basicColors: string[] = [
  "#11B911",
  "#C71585",
  "#00A6DD",
  "#7FB800",
  "#0D2C54",
  "#E10000",
  "#864BFF",
  "#17C37B",
];

export function palleteGenerator(numberOfColors: number = 5): string[] {
  if (numberOfColors <= 0 || typeof numberOfColors !== "number") {
    return [];
  }

  if (numberOfColors < basicColors.length) {
    return basicColors.slice(0, numberOfColors);
  } else {
    let remainingColors = palleteGenerator(numberOfColors - basicColors.length);
    if (remainingColors.length) {
      return basicColors.slice().concat(remainingColors);
    } else return basicColors.slice();
  }
}
/**
 *
 * @param obj
 * @returns string
 * @description This function convert an `object` into encoded URL query parameters.
 */
export const convertToEncodedURL = (obj: { [key: string]: any }): string =>
  Object.entries(obj)
    .filter(([, value]) => typeof value !== "undefined")
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join("&");

export function pickRandomFromArray<T extends any>(arr: Array<T>) {
  let i = Math.floor(Math.random() * arr.length);
  return arr[i];
}
/**
 * @description the transformToNRPOptions function is transforming a list of UserSisfotu
 * objects into a list of NRPOptsType objects, each of which has a label, a value, and the original UserSisfotu object.
 * This transformed list can be used as options for a ReactSelect component, for example.
 * @param list
 * @returns NRPOptsType[]
 */

export const transformToNRPOptions = (list: UserSisfotu[]): NRPOptsType[] =>
  list.map((val) => {
    const textLabelNrp = val.nrp ? val.nrp.toString() : "";
    if (textLabelNrp) {
      return {
        label: `${textLabelNrp} - ${val.name}`,
        value: val.id,
        ...val,
      };
    } else {
      return {
        label: `${val.nrp} - ${val.name}`,
        value: val.id,
        ...val,
      };
    }
  });

export const BackendDateFormat = "yyyy-MM-dd HH:mm:ss";

export const constructProperName = (
  firstname?: string,
  lastname?: string,
): string => {
  let name = `${firstname ?? ""} ${lastname ?? ""} `.trim();
  if (name.length) return name;
  return "-";
};

export const onlyNumber = (str: string) => {
  const onlyNumbers = /^[0-9]+$/;
  const testNumber = onlyNumbers.test(str);
  if (testNumber) return true;
  return false;
};

export const addAlphatoHexColor = (color: string, opacity: number): string => {
  const newOpacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + newOpacity.toString(16).toUpperCase();
};

export const checkIfUndefined = (item: any): item is undefined => {
  return typeof item === "undefined";
};

export const buildFormData = (
  formData: FormData,
  data: any,
  parentKey?: string,
) => {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}` : key);
    });
  } else {
    const value = data == null ? "" : data;
    formData.append(parentKey ?? "", value);
  }
};

export const jsonToFormData = (data: any): FormData => {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
};

export const downloadxls = (
  e: FormEvent,
  data: any,
  title: string,
  heading: string[][],
) => {
  e.preventDefault();
  // const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();

  //Had to create a new workbook and then add the header
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  XLSX.utils.sheet_add_aoa(ws, heading);

  //Starting in the second row to avoid overriding and skipping headers
  XLSX.utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });

  XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

  /* generate XLSX file and send to client */
  XLSX.writeFile(wb, title);
};

/**
 * @description this function will generate data to .xlsx document
 * @param e
 * @param title "the title of the file docs"
 * @param headers "this headers is optional, it will be header docs, if empty, the header docs will automatically generate from keys of the columns"
 * @param columns "this columns it will be array record for fill the value of docs"
 */
export const downloadXLS = <D extends object>({
  e,
  title,
  headers,
  columns,
}: {
  e: FormEvent;
  title: string;
  headers?: string[];
  columns: D[];
}) => {
  e.preventDefault();
  // const ws = XLSX.utils.json_to_sheet(columns);
  const wb = XLSX.utils.book_new();

  //Had to create a new workbook and then add the header
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet<D>(columns);

  let headerXLS: string[] = [];
  // if the defined headers exist, it will spread the headers array in headerXLS
  if (!!headers) {
    headerXLS = [...headers];
  } else {
    if (!!columns && columns.length > 0) {
      // columns[0] is to catch the first record for header the docs
      Object.keys(columns[0]).forEach((item) => {
        headerXLS.push(item.replaceAll("_", " ").toUpperCase());
      });
    }
  }

  XLSX.utils.sheet_add_aoa(ws, [headerXLS]);

  //Starting in the second row to avoid overriding and skipping headers
  XLSX.utils.sheet_add_json(ws, columns, { origin: "A2", skipHeader: true });

  XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

  /* generate XLSX file and send to client */
  XLSX.writeFile(wb, `${title}.xlsx`);
};

export const exportPDF = (
  _: FormEvent,
  data: string[][] | undefined,
  fileName: string,
  headers: string[][],
  portraitView: jsPDFOrientations,
  title: string,
) => {
  const unit: jsPDFUnits = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const doc = new jsPDF(portraitView, unit, size);
  doc.text(title, 40, 50);
  autoTable(doc, {
    head: headers,
    body: data,
    margin: { top: 50 },
  });

  doc.save(fileName);
};

export const downloadCSV = <D extends Object>({
  headers,
  columns,
  title,
}: CSVProps<D> & { title: string }) => {
  const csvText = createCSVFile({ headers, columns });
  const link = document.createElement("a");
  link.setAttribute("href", csvText);
  link.setAttribute("download", `${title}.csv`);
  link.style.visibility = "hidden";
  link.click();
  link.remove();
};

export const responseApprovalToastMessageFromBackend = (
  response: BackendDataShape<object>,
): string | undefined => {
  if (!response) return "";
  const { data } = response;
  if (typeof data === "object" && "approvalId" in data) {
    return "Menunggu Persetujuan";
  } else {
    return "";
  }
};

export const extractColorsFromLinearGradient = (
  linearGradient: string,
): [string, string] => {
  const matches = linearGradient.match(/#([a-f0-9]{6}|[a-f0-9]{3})/gi);
  if (matches && matches.length >= 2) {
    return [matches[0], matches[matches.length - 1]];
  }
  throw new Error("Invalid linear-gradient value");
};

export const parseLines = (value: string) => value.replace(/(\\n)/g, "\n");

export function base64ToFile(
  base64DataUrl: string | undefined,
  fileName: string,
): File | undefined {
  if (base64DataUrl) {
    const mimeType = base64DataUrl.split(",")[0].split(":")[1].split(";")[0];
    const byteString = atob(base64DataUrl.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    return new File([uint8Array], fileName, { type: mimeType });
  }
}
interface FormDataBuilderOptions {
  indices?: boolean;
}
export function generateFormDataComplexData(
  data: Record<string, any>,
  options: FormDataBuilderOptions = {},
): FormData {
  const formData = new FormData();

  const appendToFormData = (key: string, value: any, parentKey?: string) => {
    if (Array.isArray(value)) {
      value.forEach((arrayValue, index) => {
        const arrayKey = options.indices ? `${key}[${index}]` : `${key}[]`;
        appendToFormData(arrayKey, arrayValue, parentKey);
      });
    } else if (
      typeof value === "object" &&
      value !== null &&
      !(value instanceof File)
    ) {
      Object.entries(value).forEach(([nestedKey, nestedValue]) => {
        const nestedParentKey = parentKey ? `${parentKey}[${key}]` : key;
        appendToFormData(nestedKey, nestedValue, nestedParentKey);
      });
    } else {
      if (parentKey && key) {
        const finalKey = `${parentKey}[${key}]`;
        formData.append(finalKey, value);
      }
      if (parentKey && !key) {
        const finalKey = `${parentKey}`;
        formData.append(finalKey, value);
      } else {
        const finalKey = parentKey ? `${parentKey}[${key}]` : key;
        formData.append(finalKey, value);
      }
    }
  };

  Object.entries(data).forEach(([key, value]) => {
    appendToFormData(key, value);
  });

  return formData;
}

export const getFullDateWithTime = (date: string) => {
  return new Date(date).toLocaleString("id-ID");
};

export function mergeAndFormatDateAndTimeToISO(
  dateString: string,
  timeString: string,
): string {
  // Parse the date and time strings
  const parsedDate = parse(dateString, "yyyy-MM-dd", new Date());
  const parsedTime = parse(timeString, "HH:mm", new Date());

  // Combine the date and time
  const combinedDateTime = new Date(
    parsedDate.getFullYear(),
    parsedDate.getMonth(),
    parsedDate.getDate(),
    parsedTime.getHours(),
    parsedTime.getMinutes(),
  );

  return combinedDateTime.toISOString();
}

export function extractIsoDateAndTime(dateTimeString: string): {
  date: string;
  time: string;
} {
  const parsedDateTime = parse(
    dateTimeString,
    "yyyy-MM-dd'T'HH:mm:ss.SSSX",
    new Date(),
    { locale: id },
  );

  // Format the date and time
  const formattedDate = format(parsedDateTime, "yyyy-MM-dd", { locale: id });
  const formattedTime = format(parsedDateTime, "HH:mm", { locale: id });

  return { date: formattedDate, time: formattedTime };
}

export function getEnumKeyValuePairs(enumObj: any): BasicSelectOpt<string>[] {
  return Object.keys(enumObj).map((key) => ({
    label: key,
    value: enumObj[key],
  }));
}

export const convertNumberToRoman = (value: number) => {
  let roman = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  } as { [key: string]: number };

  let number = "";

  for (let i of Object.keys(roman)) {
    let q = Math.floor(value / roman[i]);
    value -= q * roman[i];
    number += i.repeat(q);
  }

  return number;
};

import { BackendDataShape } from "../../../../api/config";
import { convertToEncodedURL } from "../../../../assets/usefulFunctions";
import { AWorkUnit } from "../../../../types/endpoints/masterData/masterData";
import { masterDataStoreAPI } from "../masterDataStoreAPI";

export const masterDataWorkUnitStoreAPI = masterDataStoreAPI.injectEndpoints({
  endpoints: (builder) => {
    return {
      getWorkUnitList: builder.query({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/satkers" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AWorkUnit[]>) =>
          response.data,
        providesTags: ["WorkUnitList"],
      }),
      createWorkUnit: builder.mutation<
        BackendDataShape<object>,
        Partial<AWorkUnit>
      >({
        query: (obj) => {
          return {
            url: "/satker",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["WorkUnitList"],
      }),
      updateWorkUnit: builder.mutation<
        BackendDataShape<object>,
        Partial<AWorkUnit>
      >({
        query: ({ id, ...rest }) => {
          return {
            url: "/satker/" + id,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["WorkUnitList"],
      }),
      deleteWorkUnit: builder.mutation<
        BackendDataShape<object>,
        { id: number }
      >({
        query: ({ id }) => {
          return {
            url: `/satker/` + id,
            method: "DELETE",
          };
        },
        invalidatesTags: ["WorkUnitList"],
      }),
    };
  },
});

export const {
  useGetWorkUnitListQuery,
  useCreateWorkUnitMutation,
  useUpdateWorkUnitMutation,
  useDeleteWorkUnitMutation,
  util: { resetApiState: resetMasterDataWorkUnitAPI },
} = masterDataWorkUnitStoreAPI;

import { useState } from "react";

/**
 * @todo ini komponen yg aneh, banyak sekali value dan setternya :')
 * tolong coba direfaktor isinya atau pemakaiannya
 */
export const useModal = () => {
  const [isShown, setIsShown] = useState<boolean>(false);
  /**
   *
   * @param value diharapkan boolean
   * @returns
   */
  const toggle = (value?: any) =>
    setIsShown((prev) => {
      if (typeof value === "boolean") {
        return value;
      }
      return !prev;
    });

  return {
    isShown,
    toggle,
  };
};

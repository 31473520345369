import { APersonel } from "../../api/personelAPI";
import { transformToNRPOptions } from "../../assets/usefulFunctions";
import { useGetUserPersonelPaginationQuery } from "../../store/sisfotu/userPersonelStoreAPI";
import { BasicSelectOpt } from "../../types";
import { UserSisfotu } from "../../types/endpoints/personel";

type Props = Partial<APersonel & { nrpOrName: string; isPenerbang: boolean }>;
export interface NRPOptsType
  extends BasicSelectOpt<number>,
    Partial<
      UserSisfotu & {
        nrpOrName: string;
        isPenerbang: boolean;
        id?: number;
      }
    > {}

interface ReturnVal {
  arr: BasicSelectOpt<number>[];
  arrOpts: NRPOptsType[];
  isLoading: boolean;
  isFirstLoading: boolean;
}

const usePersonelOpts = (props: Props): ReturnVal => {
  const {
    data,
    isLoading: loading,
    isFetching,
  } = useGetUserPersonelPaginationQuery({ page: 1, take: 10, ...props });
  const isLoading = loading || isFetching;
  const isFirstLoading = loading;
  if (!data || data.entities.length < 1)
    return {
      arr: [],
      arrOpts: [],
      isLoading,
      isFirstLoading,
    };
  const list = data.entities;
  return {
    arr: transformToNRPOptions(list),
    arrOpts: transformToNRPOptions(list),
    isFirstLoading,
    isLoading,
  };
};

export default usePersonelOpts;

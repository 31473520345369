import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

import { convertToEncodedURL, jsonToFormData } from "assets/usefulFunctions";

import { APaginationEntity, BackendDataPageShape } from "types";
import { BackendPaginationRequestObject } from "types/endpoints/personel";
import {
  IGetLetterReqObj,
  ILetter,
  TInboxFormInput,
} from "types/endpoints/sisfotu/letter";

export const letterInternalStoreAPI = createApi({
  reducerPath: "letterInternalStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["LetterInternalList"],
  endpoints: (builder) => {
    return {
      getLetterInternalPagination: builder.query<
        APaginationEntity<ILetter[]>,
        BackendPaginationRequestObject<Partial<IGetLetterReqObj>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/letters/internal" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<ILetter[]>) =>
          response.data,
        providesTags: ["LetterInternalList"],
      }),
      getLetterInternalDetail: builder.query<ILetter, { id: number }>({
        query: ({ id }) => {
          let url = `/letters/internal/${id}`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<ILetter>) =>
          response.data,
        providesTags: (_, __, { id }) => [
          "LetterInternalList",
          { type: "LetterInternalList", id },
        ],
      }),
      createLetterInternal: builder.mutation<
        Pick<ILetter, "id">[],
        Partial<
          Omit<TInboxFormInput, "ccIds" | "recipientIds"> & {
            internalLetterFiles: File[];
            ccIds: string | null;
            recipientIds: string | null;
            isPublish: boolean;
          }
        >
      >({
        query: (data) => {
          const formData = jsonToFormData(data);
          return {
            url: "/letters/internal",
            body: formData,
            method: "POST",
          };
        },
        invalidatesTags: ["LetterInternalList"],
      }),
      updateLetterInternal: builder.mutation<
        Pick<ILetter, "id">[],
        {
          id: number;
          data: Partial<
            Omit<TInboxFormInput, "ccIds" | "recipientIds"> & {
              internalLetterFiles: File[];
              ccIds: string | null;
              recipientIds: string | null;
              isPublish: boolean;
            }
          >;
        }
      >({
        query: ({ id, data }) => {
          const formData = jsonToFormData(data);
          return {
            url: `/letters/internal/${id}`,
            body: formData,
            method: "PATCH",
          };
        },
        invalidatesTags: ["LetterInternalList"],
      }),
    };
  },
});

export const {
  useGetLetterInternalPaginationQuery,
  useGetLetterInternalDetailQuery,
  useCreateLetterInternalMutation,
  useUpdateLetterInternalMutation,
  util: { resetApiState: resetLetterInternalStoreAPI },
} = letterInternalStoreAPI;

import React, {
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { IoMdNotifications } from "react-icons/io";
import { MdArrowDropDown } from "react-icons/md";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Badge } from "@mui/material";
import { useNavigate } from "react-router-dom";

import useNotificationStyles from "components/PageContainer.style";
import TimeDisplays from "components/TimeDisplays";

import { COLORS } from "assets/theme";
import { puspenerbalSisfoTuMapImage } from "assets/images";
import {
  concatPerwiraLabelInNrp,
  getFullDateWithTime,
} from "assets/usefulFunctions";

import { BreadCrumbObj } from "types/breadCrumb";

import { useAppSelector } from "hooks";
import { SisfoTURoutes } from "config/nav";

import useUserIsPersonel from "hooks/identity/useUserIsPersonel";
import usePersonelId from "hooks/identity/usePersonelId";

import { useGetListNotificationQuery } from "store/notificationStore/notificationStoreAPI";
import { useGetUserPersonelPaginationQuery } from "store/sisfotu/userPersonelStoreAPI";

interface Props {
  withControl?: boolean;
  title?: string | ReactNode;
  isFixedWidth?: boolean;
  titleRightChildren?: React.ReactNode;
  children?: React.ReactNode;
  breadCrumbItems?: BreadCrumbObj[];
}

export const PageWrapper = styled.div`
  display: flex;
  height: 100vh;
`;

export const TitleRightChildrenWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  /* align-items: center; */
  margin-bottom: 8px;
`;

const StyledMainWrapper = styled.main`
  margin-top: 16px;
`;

export const StyledSisfoPuspenerbalLoginBackgroundWrapper = styled.div`
  background-color: #000000;
  background-image: url(${puspenerbalSisfoTuMapImage});
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const StyledSisfoPuspenerbalWrapper = styled.div`
  background: rgba(123, 123, 123, 0.35);
  border: 1px solid #f2f2f2;
`;
export const StyledSisfoPuspenerbalBlurredWrapper = styled.div`
  background: ${COLORS.gradient_backgroundCard};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid ${COLORS.brown_border_puspenerbal};
  color: ${COLORS.white};
`;

export const StyledOverflowX = styled.div`
  padding: 24px;
  background: ${COLORS.gradient_backgroundCard};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid ${COLORS.brown_border_puspenerbal};
  color: ${COLORS.white};
  box-sizing: border-box;
`;

export const SampleNotifications: Array<{
  id: number;
  message: string;
  time: string;
  isRead: boolean;
}> = [
  {
    id: 1,
    message:
      "Letkol Irianto Kurniawan, melakukan pembaharuan data Informasi Personel. Letkol Irianto Kurniawan, melakukan pembaharuan data Informasi Personel.",
    time: "12:38",
    isRead: false,
  },
  {
    id: 2,
    message:
      "Letkol Irianto Kurniawan, melakukan pembaharuan data Informasi Personel. Letkol Irianto Kurniawan, melakukan pembaharuan data Informasi Personel.",
    time: "12:38",
    isRead: false,
  },
  {
    id: 3,
    message:
      "Letkol Irianto Kurniawan, melakukan pembaharuan data Informasi Personel. Letkol Irianto Kurniawan, melakukan pembaharuan data Informasi Personel.",
    time: "12:38",
    isRead: true,
  },
];

const PageContainer: React.FunctionComponent<Props> = ({
  children,
  withControl,
  title,
  isFixedWidth,
  titleRightChildren,
  breadCrumbItems,
}) => {
  const classes = useNotificationStyles();
  const [isShowNotification, setIsShowNotification] = useState<boolean>(false);
  const menuNotificationRef = useRef(null) as RefObject<HTMLDivElement>;
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.userStore.userData);
  const isPersonel = useUserIsPersonel();
  const personilId = usePersonelId();

  const { data, isLoading, isFetching } = useGetListNotificationQuery({});
  const loading = isLoading || isFetching;
  /**
   * @todo use isLoading to mitigate empty data
   */
  const { data: personelData } = useGetUserPersonelPaginationQuery(
    { id: personilId ? personilId : undefined, page: 1, take: 10 },
    {
      /**
       * we will let personel to fetch data without supplying personilId
       */
      skip: !personilId && !isPersonel,
    },
  );
  const defaultUsername = user?.username;
  const username = React.useMemo(() => {
    if (isPersonel) {
      if (!personelData || !personelData.entities[0]) return defaultUsername;
      const d = personelData.entities[0];
      return concatPerwiraLabelInNrp(
        d.user_detail.nrp.toString(),
        d.user_detail.rank.rankCategoryGeneral,
      );
    }
    return defaultUsername;
  }, [isPersonel, defaultUsername, personelData]);

  const onClickNotification = () => {
    setIsShowNotification((current) => {
      return !current;
    });
  };
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        menuNotificationRef.current &&
        !menuNotificationRef.current.contains(event.target as null)
      ) {
        setIsShowNotification(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuNotificationRef]);

  return (
    <div
      className={classes.container}
      style={{ width: isFixedWidth ? "auto" : "100%" }}
    >
      <div className={classes.navContainer}>
        <TimeDisplays
          containerStyle={{
            flex: 1,
          }}
          breadCrumbItems={breadCrumbItems}
        />
        {withControl ? (
          <div className={classes.controlContainer} ref={menuNotificationRef}>
            {isShowNotification && (
              <motion.div>
                <div className={classes.notificationBox}>
                  <div className={classes.notificationItemBox}>
                    {loading ? <p>Memuat Data...</p> : null}
                    {!data?.length && !loading ? (
                      <p>Tidak ada Notifikasi</p>
                    ) : (
                      data?.map((item) => {
                        return (
                          <div
                            className={[
                              classes.notificationItem,
                              // temporary disable bcs response didn't have isRead
                              // !item.isRead ? "unread" : "",
                              "unread",
                            ].join(" ")}
                            key={item.id}
                          >
                            <div className={classes.notificationItemText}>
                              <p>{item.action}</p>
                              <div>{getFullDateWithTime(item.time)}</div>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                  <div
                    className={classes.viewAllButton}
                    onClick={() => navigate(SisfoTURoutes.notification)}
                  >
                    View All
                  </div>
                </div>
              </motion.div>
            )}
            <button
              title="notifications"
              className={classes.actionBtn}
              onClick={onClickNotification}
            >
              <Badge color="error" variant="dot">
                <IoMdNotifications
                  size={18}
                  fontSize={18}
                  color={COLORS.white}
                />
              </Badge>
            </button>
            <div>
              <Dropdown
                isOpen={showDropdown}
                toggle={() => setShowDropdown((prev) => !prev)}
              >
                <DropdownToggle
                  style={{
                    color: COLORS.white,
                    background: "none",
                    border: "none",
                    flex: 1,
                    justifyContent: "center",
                    display: "flex",
                    fontSize: 14,
                    alignItems: "center",
                  }}
                >
                  <p style={{ marginBottom: 0, marginTop: 2 }}>
                    {username ?? ""}
                  </p>
                  <MdArrowDropDown
                    size={24}
                    fontSize={24}
                    fill={COLORS.white}
                  />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    style={{ fontSize: 12 }}
                    onClick={() => navigate(SisfoTURoutes.logout)}
                  >
                    Keluar
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            {/* <button
              title="Logout"
              className={classes.logoutBtn}
              onClick={() => {
                logout();
              }}
            >
              <IoMdExit size={18} fontSize={18} />
            </button> */}
          </div>
        ) : (
          []
        )}
      </div>
      <StyledMainWrapper>
        {titleRightChildren ? (
          <TitleRightChildrenWrapper>
            <h1 className={classes.titleHeading}>{title}</h1>
            {titleRightChildren}
          </TitleRightChildrenWrapper>
        ) : (
          <h1 className={classes.titleHeading}>{title}</h1>
        )}
        <div className={classes.childrenContainer}>{children}</div>
      </StyledMainWrapper>
    </div>
  );
};

export default PageContainer;

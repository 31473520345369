import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "..";
import CookieMonster from "api/CookieMonster";
import { letUserLogin, LoginObj, UserDataWOToken } from "api/credentialsAPI";
import { ListOfRoutes } from "config/nav";
import { removeUserData } from "store/userStore";
import { convertToEncodedURL } from "assets/usefulFunctions";
import { BackendDataShape, CredsLinks } from "api/config";
import axios from "api";
import { resetPersonelStoreAPI } from "store/sisfopers/personelStore/personelStoreAPI";
import { resetUserStoreAPI } from "store/userStore/userStoreAPI";
import { resetUsertypeStoreAPI } from "store/usertypeStore/usertypeStoreAPI";
import { resetLetterCategoryStoreAPI } from "store/sisfotu/letterCategoryAPI";
import { resetLetterStoreAPI } from "store/sisfotu/letterStoreAPI/letterStoreAPI";
import { resetUserPersonelStoreAPI } from "store/sisfotu/userPersonelStoreAPI";
import { resetLovStoreAPI } from "store/sisfopers/lovStore/lovStoreAPI";
import { resetLetterInternalStoreAPI } from "store/sisfotu/letterInternalStoreAPI";
import { resetLetterExternalStoreAPI } from "store/sisfotu/letterExternalStoreAPI";
import { resetTakahStoreAPI } from "store/sisfotu/takahStore/takahStoreAPI";
import { resetIssueStoreAPI } from "store/sisfotu/issueStore/issueStoreAPI";
import { resetSatkerStoreAPI } from "store/sisfotu/satkerAPI/satkerStoreAPI";
import { resetPositionStoreAPI } from "store/sisfopers/positionStore/positionStoreAPI";
import { resetLetterTypeClassStoreAPI } from "store/sisfotu/letterTypeClassStoreAPI";
import { resetLetterPositionStoreAPI } from "store/sisfotu/letterPositionStoreAPI";

export const useClearAllCache = () => {
  const dispatch = useAppDispatch();

  return () => {
    // Tambahkan secara abjad
    dispatch(resetLetterCategoryStoreAPI());
    dispatch(resetLovStoreAPI());
    dispatch(resetLetterStoreAPI());
    dispatch(resetPersonelStoreAPI());
    dispatch(resetUserStoreAPI());
    dispatch(resetUserPersonelStoreAPI());
    dispatch(resetLetterInternalStoreAPI());
    dispatch(resetLetterExternalStoreAPI());
    dispatch(resetTakahStoreAPI());
    dispatch(resetIssueStoreAPI());
    dispatch(resetSatkerStoreAPI());
    dispatch(resetPositionStoreAPI());
    dispatch(resetLetterTypeClassStoreAPI());
    dispatch(resetLetterPositionStoreAPI());
    /**
     * @deprecated we don't use this reset store
     */
    dispatch(resetUsertypeStoreAPI());
  };
};

/**
 *
 * @param shouldNavigate in real case, we want to navigate the screen i.e. `true` on `logout`. but in testing condition, we do not need this i.e. `false`
 * @returns a logout function
 */
export const useLogout = (shouldNavigate = true) => {
  const clearAllCache = useClearAllCache();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return () => {
    // Samakan cooke yg diremove dengan cookie yg diSave
    CookieMonster.removeAllTheseCookies([
      "jwt",
      "id",
      "username",
      "usertypeId",
      "userDetailId",
      "usertypeRole",
      "usertypeName",
    ]);
    clearAllCache();
    dispatch(removeUserData());
    shouldNavigate && navigate(ListOfRoutes.loginpage);
    localStorage.clear();
  };
};

export interface GetUserDataReqObj {
  id?: number;
  user_id?: number;
  username?: string;
  email?: string;
}

export function getUser({ ...rest }: GetUserDataReqObj) {
  let params = "";
  if (rest) {
    params = convertToEncodedURL(rest);
    params = params.length ? "?" + params : "";
  }

  const url = `${CredsLinks.user(params)}`;

  return axios.get<BackendDataShape<UserDataWOToken[]>>(url, {
    data: rest,
    headers: {
      Authorization: `Bearer ${CookieMonster.loadCookie("jwt")}`,
    },
  });
}

export const useLogin = () => {
  return async (obj?: LoginObj) => {
    const {
      data: { data },
    } = await letUserLogin(obj);
    const { jwt, exp, iat, ...rest } = data;

    // Samakan cooke yg diremove dengan cookie yg diSave
    CookieMonster.saveAllTheseCookies(
      {
        jwt,
        id: rest.id,
        username: rest.username,
        // usertype: rest.usertype,
        usertypeId: rest.usertypeId,
        userDetailId: rest.userDetailId ?? undefined,
        usertypeRole: rest.usertypeRole,
        usertypeName: rest.usertypeName,
      },
      { expires: new Date(exp * 1000) },
    );
    return rest;
  };
};

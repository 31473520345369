import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

import { convertToEncodedURL } from "assets/usefulFunctions";
import { APaginationEntity, BackendDataPageShape } from "types";
import { BackendPaginationRequestObject } from "types/endpoints/personel";

import {
  AOperationalAttendance,
  AOperationalAttendancePostBackendObj,
  AOperationalAttendanceQueryParams,
} from "types/endpoints/sisfotu/operational";

export const operationalStoreAPI = createApi({
  reducerPath: "operationalStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/presence`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["PresenceList"],
  endpoints: (builder) => {
    return {
      getOperationals: builder.query<
        APaginationEntity<AOperationalAttendance[]>,
        BackendPaginationRequestObject<
          Partial<AOperationalAttendanceQueryParams>
        >
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataPageShape<AOperationalAttendance[]>,
        ) => response.data,
        providesTags: ["PresenceList"],
      }),
      getOperationalById: builder.query<
        AOperationalAttendance,
        Partial<AOperationalAttendanceQueryParams>
      >({
        query: ({ id }) => {
          let url = `/${id}`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<AOperationalAttendance>,
        ) => response.data,
        providesTags: (_, __, { id }) => [{ type: "PresenceList", id }],
      }),
      createOperational: builder.mutation<
        BackendDataShape<object>,
        Partial<Omit<AOperationalAttendancePostBackendObj, "id">> & {}
      >({
        query: ({ ...rest }) => {
          return {
            url: "",
            body: rest,
            method: "POST",
          };
        },
        invalidatesTags: ["PresenceList"],
      }),

      updateOperational: builder.mutation<
        BackendDataShape<object>,
        Partial<AOperationalAttendancePostBackendObj>
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/${id}`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["PresenceList"],
      }),
      deleteOperational: builder.mutation<
        BackendDataShape<object>,
        { id: number }
      >({
        query: ({ id }) => {
          return {
            url: `/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["PresenceList"],
      }),
    };
  },
});
export const {
  useCreateOperationalMutation,
  useUpdateOperationalMutation,
  useDeleteOperationalMutation,
  useGetOperationalByIdQuery,
  useGetOperationalsQuery,
  util: { resetApiState: resetOperationalStoreAPI },
} = operationalStoreAPI;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Form } from "reactstrap";
import { components, MultiValue } from "react-select";
import { MdSearch } from "react-icons/md";
import { toast } from "react-toastify";
import { format } from "date-fns";

import PureInputSelectPuspenerbal from "components/molecules/PureInputSelectPuspenerbal/PureInputSelectPuspenerbal";
import { ConfirmationModalPuspenerbal } from "components/Modal/ConfirmationModalPuspenerbal";
import { CancelButton, StyledButton } from "components/Modal/utils/modal.style";
import { useModal } from "components/Modal/utils/useModal";
import {
  StyledButtonContainer,
  StyledSubmitFormButtonWrapper,
} from "components/Modal/sisfo/sisfo.style";
import { LineSeparator } from "components/molecules/PuspenerbalModal/PuspenerbalModal";
import InputText from "components/InputForm/InputText";
import StyledToastBodyPuspenerbal from "components/Toast/StyledToastBodyPuspenerbal";

import {
  useCreateLetterMutation,
  useUpdateLetterMutation,
} from "store/sisfotu/letterStoreAPI/letterStoreAPI";

import {
  ALetterResponse,
  ALetterRequest,
} from "types/endpoints/sisfotu/letter";

import { COLORS } from "assets/theme";

import useLetterCategoryOpts, {
  ACategoryLetterOpt,
} from "hooks/management/useLetterCategoryOpts";
import usePersonelOpts, { NRPOptsType } from "hooks/management/usePersonelOpts";
import useDebounce from "hooks/tools/useDebounce";

type Props = {
  hide: () => void;
  isEditing?: boolean;
  data?: ALetterResponse;
};

const LetterForm = ({ hide, isEditing, data }: Props) => {
  const defaultNomorSurat = data?.letterNumber ?? "";
  const defaultPesanSurat = data?.textArea ?? "";

  const defaultIsPublished = isEditing && data ? data.isPublished : false;

  const [createLetter] = useCreateLetterMutation();
  const [updateLetter] = useUpdateLetterMutation();
  const [isDraft, setIsDraft] = React.useState<boolean>(
    defaultIsPublished || false,
  );
  const { toggle: toggleConfirm, isShown: isShowConfirm } = useModal();
  const { toggle: toggleConfirmCancel, isShown: isShowConfirmCancel } =
    useModal();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [searchPenerimaSurat, setSearchPenerimaSurat] = React.useState<
    string | null
  >("");

  const { arr: categoryLetterOpts } = useLetterCategoryOpts();
  const defaultCategorySurat =
    categoryLetterOpts?.find((item) => item.value === data?.code) ?? null;
  const [categorySurat, setCategorySurat] =
    React.useState<ACategoryLetterOpt | null>(defaultCategorySurat);

  const [nomorSurat, setNomorSurat] = React.useState<string>(defaultNomorSurat);
  const [pesan, setPesan] = React.useState<string>(defaultPesanSurat);

  const debouncedSearchPenerimaSurat = useDebounce(searchPenerimaSurat, 500);
  const isSuratPerintahTerbang = React.useMemo(() => {
    if (!categorySurat?.value) return false;
    if (categorySurat?.value.toLowerCase() === "surat perintah terbang") {
      return true;
    } else {
      return false;
    }
  }, [categorySurat?.value]);

  const { arrOpts: personelsOpts, isLoading: isLoadingPersonelOpts } =
    usePersonelOpts({
      nrpOrName: debouncedSearchPenerimaSurat
        ? debouncedSearchPenerimaSurat
        : undefined,
      isPenerbang: isSuratPerintahTerbang,
    });

  const receiversDataDefaultValue = React.useMemo<
    NRPOptsType[] | undefined
  >(() => {
    if (!data) return [];
    if (data) {
      const values = [
        {
          value: data.toUser.id,
          label: data.toUser.nrp,
        },
      ];
      return values;
    }
  }, [data]);

  const [penerimaSurat, setPenerimaSurat] =
    React.useState<MultiValue<NRPOptsType> | null>();

  const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);
  const handleInputChange = (newValue: string) => {
    setSearchPenerimaSurat(newValue);
    if (newValue) {
      setIsDropdownOpen(true);
    } else {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (categorySurat?.value) {
      setPesan(categorySurat?.textArea ?? "");
    }
  }, [categorySurat]);

  /**
   *
   * @param props DropdownIndicatorProps
   * @returns JSX.Element
   * @todo refactor this DropdownIndicator props using DropdownIndicatorProps later.
   */
  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <MdSearch size={16} />
      </components.DropdownIndicator>
    );
  };
  /**
   *
   * @param props ClearIndicatorProps
   * @returns JSX.Element
   * @todo refactor this ClearIndicator props using ClearIndicatorProps later.
   */
  const ClearIndicator = (props: any) => {
    return <components.ClearIndicator {...props} />;
  };

  const disabledAddEmailValidator =
    isSuratPerintahTerbang === false
      ? !pesan || !nomorSurat || penerimaSurat?.length === 0
      : !pesan || !nomorSurat || penerimaSurat?.length === 0;

  const handleSubmit = React.useCallback<
    React.FormEventHandler<HTMLFormElement>
  >(
    (e) => {
      e.preventDefault();
      setIsSubmitting(true);

      const body = pesan;
      const toNRP = penerimaSurat?.map((item) => {
        if (item && item.value) {
          return item.value;
        } else {
          return 0;
        }
      });

      const submitData: ALetterRequest = {
        letterNumber: nomorSurat,
        textArea: body,
        to: Number(toNRP),
        createdDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
      };

      if (isEditing) {
        if (!data || !data.id) return;
        updateLetter({ id: data.id, ...submitData, isPublished: !isDraft })
          .unwrap()
          .then(() => {
            hide();
            toggleConfirm();
            setIsSubmitting(false);
            toast.success(
              <StyledToastBodyPuspenerbal
                headerText="Berhasil"
                description={`Anda berhasil mengubah data Surat.`}
              />,
            );
          })
          .catch(() => {
            toggleConfirm();
            setIsSubmitting(false);
            toast.error(
              <StyledToastBodyPuspenerbal
                headerText="Gagal"
                description={`Anda gagal mengubah data Surat.`}
              />,
            );
          });
      } else {
        createLetter({ ...submitData, isDraft })
          .unwrap()
          .then(() => {
            hide();
            if (isDraft) {
              toggleConfirmCancel();
            } else {
              toggleConfirm();
            }
            setIsSubmitting(false);
            toast.success(
              <StyledToastBodyPuspenerbal
                headerText="Berhasil"
                description={`Anda berhasil menambahkan data Surat.`}
              />,
            );
          })
          .catch(() => {
            if (isDraft) {
              toggleConfirmCancel();
            } else {
              toggleConfirm();
            }
            setIsSubmitting(false);
            toast.error(
              <StyledToastBodyPuspenerbal
                headerText="Gagal"
                description={`Anda gagal menambahkan data Surat.`}
              />,
            );
          });
      }
    },
    [
      data,
      isEditing,
      categorySurat,
      toggleConfirm,
      penerimaSurat,
      isDraft,
      nomorSurat,
      pesan,
      toggleConfirmCancel,
      isSuratPerintahTerbang,
    ],
  );

  return (
    <div>
      <Form id="letterForm" onSubmit={handleSubmit}>
        <ConfirmationModalPuspenerbal
          hide={toggleConfirmCancel}
          onCancel={toggleConfirmCancel}
          onConfirm={() => {
            setIsDraft(true);
          }}
          isSubmitOnProgress={isSubmitting}
          isShowConfirm={isShowConfirmCancel}
          idForm="letterForm"
          typeConfirmButton="submit"
          type="confirm-add"
          headerText="Konfirmasi"
          confirmText="YA, SIMPAN"
          cancelText="BATALKAN"
          message={`Apakah anda yakin untuk menyimpan surat sebagai draft?`}
          isDeleteConfirm={false}
        />
        <ConfirmationModalPuspenerbal
          hide={toggleConfirm}
          onCancel={toggleConfirm}
          message="Apakah anda yakin ingin membatalkan penambahan Surat? Data yang telah diisi akan hilang jika melanjutkan pembatalan."
          isShowConfirm={isShowConfirm}
          idForm="letterForm"
          isSubmitOnProgress={isSubmitting}
          typeConfirmButton="submit"
          type="confirm-add"
          headerText="Konfirmasi"
          confirmText={"Surat baru"}
          cancelText="Batalkan"
          isDeleteConfirm={false}
        />
        <PureInputSelectPuspenerbal
          placeholder="Kategori Surat..."
          options={categoryLetterOpts}
          label="Kategori Surat"
          hideBorderOption
          value={categorySurat}
          isClearable
          onChange={(val) => setCategorySurat(val)}
          noOptionsMessage={(object) => `${object.inputValue} tidak ditemukan`}
        />
        <LineSeparator />
        <PureInputSelectPuspenerbal
          components={{
            DropdownIndicator: DropdownIndicator,
            ClearIndicator: ClearIndicator,
          }}
          placeholder="Penerima (NRP/Nama)..."
          controlStyle={{ cursor: isLoadingPersonelOpts ? "wait" : "text" }}
          multiValueStyle={{
            background: COLORS.chip_1_sisfotu_dropdown,
            border: `2px solid ${COLORS.light_grey_3_puspenerbal}`,
          }}
          valueContainerStyle={{ maxWidth: 300 }}
          options={personelsOpts}
          label={"Penerima"}
          hideBorderOption
          menuIsOpen={isDropdownOpen}
          isMulti
          value={penerimaSurat}
          required
          isClearable
          defaultValue={receiversDataDefaultValue}
          isSearchable
          noOptionsMessage={(object) => `${object.inputValue} tidak ditemukan`}
          isLoading={isLoadingPersonelOpts}
          onChange={(val) => setPenerimaSurat(val)}
          onInputChange={handleInputChange}
        />
        <InputText
          label="Nomor Surat"
          placeholder="Nomor Surat..."
          required
          value={nomorSurat}
          onChange={(e) => setNomorSurat(e.target.value)}
        />

        <InputText
          label="Pesan"
          placeholder="Pesan..."
          required
          type="textarea"
          style={{ minHeight: 120 }}
          value={pesan}
          onChange={(e) => setPesan(e.target.value)}
        />
        <StyledButtonContainer>
          <StyledSubmitFormButtonWrapper>
            <CancelButton
              type="button"
              disabled={disabledAddEmailValidator}
              onClick={toggleConfirmCancel}
            >
              SIMPAN DRAFT
            </CancelButton>
            <StyledButton
              type="button"
              disabled={disabledAddEmailValidator}
              onClick={toggleConfirm}
            >
              {"KIRIM"}
            </StyledButton>
          </StyledSubmitFormButtonWrapper>
        </StyledButtonContainer>
      </Form>
    </div>
  );
};

export default LetterForm;

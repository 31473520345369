import { FC, Fragment } from "react";
import ReactDOM from "react-dom";
import {
  CloseButton,
  Header,
  HeaderText,
  StyledButton,
  Wrapper,
} from "./utils/modal.style";
import { motion } from "framer-motion";
import {
  PuspenerbalModalProps,
  StyledPuspenerbalModal,
} from "../molecules/PuspenerbalModal/PuspenerbalModal";
import styled from "styled-components";
import { MdClose } from "react-icons/md";
import { COLORS } from "../../assets/theme";
import { easing } from "../../config/transitionProps";
import { CircularProgress } from "@mui/material";

interface ConfirmationProps {
  confirmText: string;
  isDeleteConfirm: boolean;
  cancelText: string;
  message: string;
  idForm?: string;
  typeConfirmButton?: "button" | "submit" | "reset";
}

const StyledConfirmationMessage = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: white;
`;

const StyledButtonWrapper = styled.div`
  margin-top: 20px;
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ConfirmationModalPuspenerbal: FC<
  /**
   * @todo tolong extends props yg dibutuhkan saja.
   * dan jika props itu wajib, tolong diimplement.
   * jika tidak tolong dijadikan optional atau dihilangkan sama sekali
   */
  PuspenerbalModalProps & ConfirmationProps
> = ({
  hide,
  isShowConfirm,
  withCloseIcon,
  headerText,
  message,
  confirmText,
  onConfirm,
  onCancel,
  modalContent,
  isDeleteConfirm,
  isDelete,
  isEdit,
  isShown,
  cancelText,
  idForm,
  typeConfirmButton,
  isSubmitOnProgress,
}: PuspenerbalModalProps & ConfirmationProps) => {
  const rendering = isShowConfirm || isShown || isDelete || isEdit;
  if (!rendering) return null;
  const confirmationModal = (
    <Fragment>
      <motion.div
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          background: "rgba(0, 0, 0, 0.3)",
          zIndex: 10000,
        }}
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: { duration: 0.6, ease: easing },
        }}
        onClick={hide}
        exit={{ opacity: 0 }}
      />
      <Wrapper style={{ zIndex: 10001 }}>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.6, ease: easing },
          }}
          exit={{ opacity: 0, y: 100 }}
        >
          <StyledPuspenerbalModal style={{ maxWidth: "40rem" }}>
            <div>
              <Header style={{ marginBottom: 8 }}>
                <HeaderText style={{ fontSize: 18, color: COLORS.white }}>
                  {headerText}
                </HeaderText>
                {withCloseIcon && (
                  <CloseButton onClick={hide}>
                    <MdClose size={20} color={COLORS.white} />
                  </CloseButton>
                )}
              </Header>
              <StyledConfirmationMessage>{message}</StyledConfirmationMessage>
              <div>{modalContent}</div>
              <StyledButtonWrapper>
                <StyledButton
                  type="button"
                  onClick={onCancel}
                  style={{
                    background: COLORS.light_grey_4_puspenerbal,
                    color: COLORS.black_3,
                    textTransform: "uppercase",
                    fontWeight: 700,
                  }}
                >
                  {cancelText ? cancelText : "BATALKAN"}
                </StyledButton>
                <StyledButton
                  type={typeConfirmButton ? typeConfirmButton : "button"}
                  form={idForm}
                  disabled={isSubmitOnProgress}
                  onClick={onConfirm}
                  style={{
                    background: isDeleteConfirm
                      ? COLORS.red_1_puspenerbal
                      : COLORS.blue_1_puspenerbal,
                    textTransform: "uppercase",
                    fontWeight: 700,
                  }}
                >
                  {isSubmitOnProgress ? (
                    <CircularProgress
                      size={16}
                      color="inherit"
                      style={{
                        color: COLORS.white,
                        marginTop: -3,
                      }}
                    />
                  ) : confirmText ? (
                    confirmText
                  ) : (
                    "SIMPAN"
                  )}
                </StyledButton>
              </StyledButtonWrapper>
            </div>
          </StyledPuspenerbalModal>
        </motion.div>
      </Wrapper>
    </Fragment>
  );
  return ReactDOM.createPortal(
    confirmationModal,
    document.getElementById("portal")!,
  );
};

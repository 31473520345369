import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

import {
  convertToEncodedURL,
  generateFormDataComplexData,
} from "assets/usefulFunctions";

import { ACategoryLetter } from "types/endpoints/sisfotu/categoryLetter";

export const letterCategoryStoreAPI = createApi({
  reducerPath: "letterCategoryStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/templates`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["CategoryLetterList"],
  endpoints: (builder) => {
    return {
      getLetterCategory: builder.query<
        ACategoryLetter[],
        Partial<ACategoryLetter>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<ACategoryLetter[]>) =>
          response.data,
        providesTags: ["CategoryLetterList"],
      }),
      createLetterCategory: builder.mutation<
        BackendDataShape<object>,
        Partial<ACategoryLetter>
      >({
        query: (obj) => {
          return {
            url: "",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["CategoryLetterList"],
      }),
      updateLetterCategory: builder.mutation<
        BackendDataShape<object>,
        Partial<ACategoryLetter>
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/${id}`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["CategoryLetterList"],
      }),
      deleteLetterCategory: builder.mutation<
        BackendDataShape<ACategoryLetter>,
        object & { id: number }
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/${id}`,
            body: rest,
            method: "DELETE",
          };
        },
        invalidatesTags: ["CategoryLetterList"],
      }),

      restoreLetterCategory: builder.mutation<
        BackendDataShape<object>,
        { file: File }
      >({
        query: ({ ...rest }) => {
          return {
            url: "/restore",
            body: generateFormDataComplexData(rest),
            method: "POST",
          };
        },
        invalidatesTags: ["CategoryLetterList"],
      }),
    };
  },
});

export const {
  useRestoreLetterCategoryMutation,
  useGetLetterCategoryQuery,
  useCreateLetterCategoryMutation,
  useDeleteLetterCategoryMutation,
  useUpdateLetterCategoryMutation,
  util: { resetApiState: resetLetterCategoryStoreAPI },
} = letterCategoryStoreAPI;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

import { convertToEncodedURL } from "assets/usefulFunctions";
import { APaginationEntity, BackendDataPageShape } from "types";
import { BackendPaginationRequestObject } from "types/endpoints/personel";

import {
  ATemplateLetter,
  ATemplateLetterPostObj,
  ATemplateLetterQueryParamsObj,
} from "types/endpoints/sisfotu/templateLetter";

export const letterTemplateStoreAPI = createApi({
  reducerPath: "letterTemplateStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/templates`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["TemplateLetterList"],
  endpoints: (builder) => {
    return {
      getLetterTemplate: builder.query<
        APaginationEntity<ATemplateLetter[]>,
        BackendPaginationRequestObject<Partial<ATemplateLetterQueryParamsObj>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataPageShape<ATemplateLetter[]>,
        ) => response.data,
        providesTags: ["TemplateLetterList"],
      }),
      createLetterTemplate: builder.mutation<
        BackendDataShape<object>,
        Omit<ATemplateLetterPostObj, "id">
      >({
        query: (obj) => {
          return {
            url: "",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["TemplateLetterList"],
      }),
      updateLetterTemplate: builder.mutation<
        BackendDataShape<object>,
        ATemplateLetterPostObj
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/${id}`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["TemplateLetterList"],
      }),
      deleteLetterTemplate: builder.mutation<
        BackendDataShape<object>,
        { id: number }
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/${id}`,
            body: rest,
            method: "DELETE",
          };
        },
        invalidatesTags: ["TemplateLetterList"],
      }),
    };
  },
});

export const {
  useGetLetterTemplateQuery,
  useCreateLetterTemplateMutation,
  useDeleteLetterTemplateMutation,
  useUpdateLetterTemplateMutation,
  util: { resetApiState: resetLetterTemplateStoreAPI },
} = letterTemplateStoreAPI;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

import { convertToEncodedURL, jsonToFormData } from "assets/usefulFunctions";

import { APaginationEntity, BackendDataPageShape } from "types";
import { BackendPaginationRequestObject } from "types/endpoints/personel";
import {
  IGetLetterReqObj,
  ILetter,
  TOutboxFormInput,
} from "types/endpoints/sisfotu/letter";

export const letterExternalStoreAPI = createApi({
  reducerPath: "letterExternalStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["LetterExternalList"],
  endpoints: (builder) => {
    return {
      getLetterExternalPagination: builder.query<
        APaginationEntity<ILetter[]>,
        BackendPaginationRequestObject<Partial<IGetLetterReqObj>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/letters/external" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<ILetter[]>) =>
          response.data,
        providesTags: ["LetterExternalList"],
      }),
      getLetterExternalDetail: builder.query<ILetter, { id: number }>({
        query: ({ id }) => {
          let url = `/letters/external/${id}`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<ILetter>) =>
          response.data,
        providesTags: (_, __, { id }) => [
          "LetterExternalList",
          { type: "LetterExternalList", id },
        ],
      }),
      createLetterExternal: builder.mutation<
        Pick<ILetter, "id">[],
        Partial<
          Omit<TOutboxFormInput, "ccIds" | "recipientIds"> & {
            externalLetterFiles: File[];
            ccIds: string | null;
            recipientIds: string | null;
            isPublish: boolean;
          }
        >
      >({
        query: (data) => {
          const formData = jsonToFormData(data);
          return {
            url: "/letters/external",
            body: formData,
            method: "POST",
          };
        },
        invalidatesTags: ["LetterExternalList"],
      }),
      updateLetterExternal: builder.mutation<
        Pick<ILetter, "id">[],
        {
          id: number;
          data: Partial<
            Omit<TOutboxFormInput, "ccIds" | "recipientIds"> & {
              externalLetterFiles: File[];
              ccIds: string | null;
              recipientIds: string | null;
              isPublish: boolean;
            }
          >;
        }
      >({
        query: ({ id, data }) => {
          const formData = jsonToFormData(data);
          return {
            url: `/letters/external/${id}`,
            body: formData,
            method: "PATCH",
          };
        },
        invalidatesTags: ["LetterExternalList"],
      }),
    };
  },
});

export const {
  useGetLetterExternalPaginationQuery,
  useGetLetterExternalDetailQuery,
  useCreateLetterExternalMutation,
  useUpdateLetterExternalMutation,
  util: { resetApiState: resetLetterExternalStoreAPI },
} = letterExternalStoreAPI;

import styled from "styled-components";
import { large, medium, small, xSmall } from "../../../assets/breakpoints";
import { COLORS } from "../../../assets/theme";

export const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transition: all 0.2s;
  ${xSmall} {
    transform: translate(-50%, -50%) scale(0.75);
  }
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: max-content;
  outline: 0;
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: all 0.2s;
  background: rgba(0, 0, 0, 0.3);
  z-index: 700;
`;

export const StyledModal = styled.div`
  z-index: 100;
  background: white;
  position: relative;
  padding: 3rem;
  margin: auto;
  min-width: fit-content;
  border-radius: 8px;
  max-height: 100vh;
  /* overflow: auto; */
`;

export const Header = styled.div`
  border-radius: 8px 8px 0 0;
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  color: #22232b;

  @media print {
    display: none;
  }
`;

export const Footer = styled.div`
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  padding: 0.6px 0px;
  color: #22232b;
  margin-top: 3rem;
`;

export const HeaderText = styled.div`
  color: #22232b;
  align-self: center;
  font-weight: bold;
  letter-spacing: 0.02em;
  font-size: 15px;
`;

export const CloseButton = styled.button`
  font-size: 1.3rem;
  border: none;
  border-radius: 3px;
  background: none;
  :hover {
    cursor: pointer;
  }
`;

export const UploadButton = styled.button`
  padding: 1.2rem 3rem;
  background: #ecedf5;
  height: 38px;
  color: #1c1c24;
  font-weight: 600;
  line-height: 12px;
  font-size: 1.1rem;
  border: none;
  :hover {
    cursor: pointer;
  }
  :disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;
export const CancelButton = styled.button`
  padding: 1.2rem 3rem;
  text-transform: uppercase;
  background: #ecedf5;
  height: 38px;
  color: ${COLORS.black_3};
  font-weight: 600;
  line-height: 12px;
  font-size: 1.1rem;
  border: none;
  :hover {
    cursor: pointer;
  }
  :disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

export const StyledButton = styled.button`
  padding: 1.2rem 3rem;
  white-space: nowrap;
  height: 38px;
  border: none;
  font-size: 1.1rem;
  line-height: 12px;
  background: ${COLORS.blue_1};
  font-weight: 600;
  color: #fff;
  :hover {
    cursor: pointer;
  }
  :disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;
export const SubmitButton = styled.button`
  padding: 1rem 3rem;
  height: 38px;
  border: none;
  font-size: 1.1rem;
  line-height: 12px;
  background: ${COLORS.blue_1};
  font-weight: 600;
  color: #fff;
  :hover {
    cursor: pointer;
  }
  :disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

export const DeleteButton = styled.button`
  padding: 1.2rem 3rem;
  white-space: nowrap;
  height: 38px;
  border: none;
  font-size: 1.1rem;
  line-height: 12px;
  background: ${COLORS.red_1_puspenerbal};
  font-weight: 600;
  color: white;
  :hover {
    cursor: pointer;
  }
  :disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

export const WrapperModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  width: inherit;
  outline: 0;
`;

export const ConfirmationButtonWrapper = styled.div`
  display: flex;
`;

export const Message = styled.div`
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 18px;
  padding: 1rem 0 3rem 0;
  text-align: left;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  ${large} {
    width: 100%;
  }
  width: 25vw;
`;

export const YesButton = styled.button`
  font-size: 0.9rem;
  padding: 10px 45px;
  border: none;
  border-radius: 4px;
  background: #f05454;
  font-weight: 600;
  margin-left: 1rem;
  color: #ffffff;
  :hover {
    cursor: pointer;
  }
  :disabled {
    opacity: 0.7;
  }
`;

export const ResetButton = styled.button`
  font-size: 0.9rem;
  padding: 10px 45px;
  border: none;
  border-radius: 4px;
  background: #fdaa09;
  font-weight: 600;
  margin-left: 1rem;
  color: #ffffff;
  :hover {
    cursor: pointer;
  }
  :disabled {
    opacity: 0.7;
  }
`;

export const NoButton = styled.button`
  font-size: 0.9rem;
  padding: 10px 45px;
  border: none;
  border-radius: 4px;
  background: #ecedf5;
  color: #22232b;
  font-weight: 600;
  margin-left: auto;
  :hover {
    cursor: pointer;
  }
`;

export const ManageWrapper = styled.div`
  width: 80%;
  ${small} {
    width: 80%;
  }
  ${medium} {
    width: 60%;
  }
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  width: inherit;
  outline: 0;
`;

export const VerifyEmailButton = styled.button`
  padding: 1rem 2.8rem;
  height: 32px;
  border: none;
  font-size: 0.9rem;
  border-radius: 4px;
  background: #20c997;
  font-weight: 600;
  color: #fff;
  :hover {
    cursor: pointer;
  }
  :disabled {
    cursor: not-allowed;
    background: #a5aec2;
  }
`;

export const VerifyPhoneButton = styled.button`
  padding: 1rem 2.8rem;
  height: 32px;
  border: none;
  font-size: 0.9rem;
  border-radius: 4px;
  margin-left: 1rem;
  background: #3699ff;
  font-weight: 600;
  color: #fff;
  :hover {
    cursor: pointer;
  }
  :disabled {
    cursor: not-allowed;
    background: #a5aec2;
  }
`;

export const Content = styled.div`
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 18px;
  padding: 1rem 0 3rem 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  ${large} {
    width: 100%;
  }
`;

export const VerifyModal = styled.div`
  width: 35%;
  ${small} {
    width: 50%;
  }
  ${medium} {
    width: 35%;
  }
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  outline: 0;
  text-align: center;
`;

export const VerifyButton = styled.button`
  padding: 1rem 2.8rem;
  height: 32px;
  border: none;
  font-size: 0.9rem;
  border-radius: 4px;
  background: #3699ff;
  font-weight: 600;
  color: #fff;
  :hover {
    cursor: pointer;
  }
  :disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

import { convertToEncodedURL } from "assets/usefulFunctions";
import { APaginationEntity, BackendDataPageShape } from "types";
import { BackendPaginationRequestObject } from "types/endpoints/personel";

import {
  ALetterParams,
  ALetterReportObj,
} from "types/endpoints/sisfotu/letter";

export const letterReportStoreAPI = createApi({
  reducerPath: "letterReportStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/letters`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["LetterInternalList", "LetterExternalList"],
  endpoints: (builder) => {
    return {
      getInternalLetter: builder.query<
        APaginationEntity<ALetterReportObj[]>,
        BackendPaginationRequestObject<Partial<ALetterParams>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/internal" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataPageShape<ALetterReportObj[]>,
        ) => {
          const { meta, entities } = response.data;
          const dataWithChip = entities.map((val) => {
            return {
              ...val,
            };
          });
          return { meta, entities: dataWithChip };
        },
        providesTags: ["LetterInternalList"],
      }),
      getExternalLetter: builder.query<
        APaginationEntity<ALetterReportObj[]>,
        BackendPaginationRequestObject<Partial<ALetterParams>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/external" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataPageShape<ALetterReportObj[]>,
        ) => {
          const { meta, entities } = response.data;
          const dataWithChip = entities.map((val) => {
            return {
              ...val,
            };
          });
          return { meta, entities: dataWithChip };
        },
        providesTags: ["LetterExternalList"],
      }),
    };
  },
});
export const {
  useGetInternalLetterQuery,
  useGetExternalLetterQuery,
  util: { resetApiState: resetLetterReportStoreAPI },
} = letterReportStoreAPI;

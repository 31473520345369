export interface FontSizes {
  sm: number;
  md: number;
  lg: number;
}

export interface ChartColor {
  red: string;
  lightRed: string;
  green: string;
  lightGreen: string;
  blue_1: string;
  purple_1: string;
  yellow: string;
  green_1: string;
}

export interface BarColor {
  vivid_sky_blue: string;
  blue_munsell: string;
  curious_blue: string;
  bahama_blue: string;
  persian_green: string;
  atoll: string;
}

export const widthBreakpoints = [480, 768, 992, 1024, 1280, 1366, 1920];
export const mq = widthBreakpoints.map((bp) => `@media (min-width: ${bp}px)`);

export function genKeyframes(keyname: string) {
  return `@keyframes ${keyname}`;
}

export interface BasicTheme {
  barheight: string;
  sidebarBackgroundColor: string;
  sidebarSelected: string;
  backgroundColor: string;
  cardHeaderColor: string;
  primaryColorLightest: string;
  primaryColorDarkest: string;
  darkGray: string;
  dangerColor: string;
  codeColor: string;
  fontSizes: FontSizes;
  blue: string;
  secondaryBlue: string;
  white: string;
  black: string;
  secondaryBlack: string;
  gray: string;
  lightGray: string;
  chart: ChartColor;
  bar: BarColor;
  loginBackground: string;
  inputLogin: string;
  black_1: string;
}

export const themeA: BasicTheme = {
  barheight: "4rem",
  sidebarBackgroundColor: "#22232B",
  sidebarSelected: "#353844",
  backgroundColor: "#EEF1F8",
  cardHeaderColor: "#20A8D8",
  primaryColorDarkest: "#A21A91",
  primaryColorLightest: "#F1B0E9",
  codeColor: "#d63384",
  fontSizes: {
    sm: 10,
    md: 15,
    lg: 20,
  },
  // based on desgyn system
  blue: "#376EB5",
  secondaryBlue: "#3699FF",
  white: "#FFFFFF",
  black: "#22232B",
  dangerColor: "#F05454",
  secondaryBlack: "#303139",
  gray: "#A5AEC2",
  lightGray: "#C4CDE0",
  chart: {
    red: "#FC5A5A",
    lightRed: "#FFE5E5",
    green: "#20C997",
    lightGreen: "#DCFFF5",
    blue_1: "#3699FF",
    purple_1: "#7B61FF",
    yellow: "#FDAA09",
    green_1: "#20C997",
  },
  loginBackground: "#073572",
  inputLogin: "#254a81",
  black_1: "#1C1C24",
  darkGray: "#687484",
  bar: {
    vivid_sky_blue: "#01CEEB",
    blue_munsell: "#0A95A8",
    curious_blue: "#1486DC",
    bahama_blue: "#0964A9",
    persian_green: "#009687",
    atoll: "#0C7368",
  },
};

export const COLORS = {
  white: "#FFFFFF",
  cyan: "#00BBD5",
  light_grey_1: "#EEF1F8",
  light_grey_2: "#C4CDE0",
  grey: "#A5AEC2",
  grey_2: "#D9D9D9",
  dark_grey_1: "#8997A9",
  dark_grey_2: "#687484",
  brown_border_puspenerbal: "#7b7b7b",
  black_1: "#1C1C24",
  black_2: "#13131A",
  black_3: "#000000",
  blue_1_puspenerbal: "#18A0FB",
  backgroundCard: "rgba(200, 200, 200, 0.15)",
  // gradient_backgroundCard: "linear-gradient(180deg, #000912 0%, #002244 100%)",
  gradient_backgroundCard: "linear-gradient(180deg, #000000 0%, #232321 100%)",
  gradient_red_chart: "linear-gradient(180deg, #DA4453 0%, #89216B 100%)",
  gradient_blue_chart: "linear-gradient(180deg, #3A7BD5 0%, #00D2FF 100%)",
  gradient_green_chart: "linear-gradient(180deg, #11998E 0%, #38EF7D 100%)",
  gradient_yellow_chart: "linear-gradient(180deg, #F37500 0%, #FFD644 100%)",
  gradient_purple_chart: "linear-gradient(180deg, #53008D 0%, #A044FF 100%)",

  gradient_blue: "rgba(24, 160, 251, 0.1)",
  gradient_blue_1: "rgba(24, 160, 251, 0.3)",
  gradient_blue_table_expanded_puspenerbal: "rgba(24, 160, 251, 0.65)",
  gradient_table_expanded_puspenerbal: "rgba(0, 0, 0, 0.2)",
  gradient_blue_card:
    "linear-gradient(0deg, rgba(24, 160, 251, 0.1), rgba(24, 160, 251, 0.1)), linear-gradient(180deg, rgba(0, 9, 18, 0.5) 0%, rgba(0, 34, 68, 0.5) 100%)",
  gradient_blue_puspenerbal_card:
    "linear-gradient(180deg, #000912 0%, #002244 100%)",
  light_blue_1: "#E3F1FF",
  light_blue_2: "#CEE6FF",
  blue_1: "#3699FF",
  blue_2: "#1486DC",
  blue_3: "#376EB5",
  blue_4: "#24C4CE",
  blue_5: "#376EB5",

  green_1: "#20C997",
  green_2: "#169E50",
  green_3: "#CCDD1E",
  green_4: "#009687",
  green_5: "#2ECC71",
  green_6: "rgba(204, 172, 123, 0.25)",
  green_7: "#CAC203",

  green_1_puspenerbal: "#00C853",
  light_green: "#DCFFF5",

  black_1_puspenerbal: "rgba(0, 0, 0, 0.45)",
  black_2_puspenerbal: "rgba(0, 0, 0, 0.1)",
  black_3_puspenerbal: "rgba(0, 0, 0,  0.95)",
  light_darkbrown_1_puspenerbal: "#505157",
  light_brown_1_puspenerbal: "rgba(219, 219, 219, 0.15)",
  dark_brown_1_puspenerbal: "#232426",
  light_grey_1_puspenerbal: "#9F9F9F",
  light_grey_2_puspenerbal: "#C8C8C8",
  light_grey_4_puspenerbal: "#E1E4E1",
  darkerGreen: "#1E8263",
  darkerGreen_2: "#158362",
  chip_1_sisfotu_dropdown: "rgba(123, 123, 123, 0.15)",
  color_header_table_sisfotu: "rgba(108, 110, 133, 0.5)",
  yellow: "#FDAA09",
  yellow_2: "#FFB800",
  yellow_3: "#FFA336",
  yellow_puspenerbal_1: "#FFF500",
  light_yellow: "#FFF2DA",
  light_yellow_2: "#FFF6E3",
  darkerYellow: "#b87d07",
  red: "#FC5A5A",
  red_1: "#F05454",
  red_1_puspenerbal: "#F44336",
  red_duplicate_selected: "#6E1610",
  red_duplicate: "#FB5F54",
  gradient_red_error_puspenerbal:
    "linear-gradient(0deg, rgba(255, 96, 88, 0.15), rgba(255, 96, 88, 0.15)), rgba(0, 0, 0, 0.45)",
  red_2: "#ED7068",
  gradient_purple_dashboard: "linear-gradient(0deg, #DA4453 0%, #89216B 100%)",
  light_red: "#FFE5E5",
  light_red_2: "#FFDBD9",
  light_grey_3_puspenerbal: "#7B7B7B",
  gradient_linear_card: "linear-gradient(180deg, #000000 0%, #232321 100%)",
  brown_1_puspenerbal: "#FFD79A",
  darkerRed: "#9A383A",
  gray_1_sisfotu_puspenerbal: "rgba(190, 190, 190, 0.1)",
  gray_2_sisfotu_puspenerbal: "rgba(108, 110, 133, 0.5)",
  secondaryBlue: "#3699FF",
  gray_1_puspenerbal: "1px solid rgba(219, 219, 219, 0.15)",
  purple_1: "#7B61FF",
  purple_2: "#9C1AB1",
  purple_3: "#89216B",
  purple_4: "#6A3093",
  purple_5: "#DA4453",
  purple_1_puspenerbal: "#8974EF",
  purple_6: "rgba(218, 140, 255, 0.3)",
  purple_7: "rgba(118, 66, 142, 0.3)",
  purple_8: "#DA8CFF",
  brown_1: "#B89A8E",
  brown_2: "#7A5547",
  imperial_red: {
    name: "Imperial Red",
    color: "#FB5F54",
  },
  gold: {
    name: "Gold",
    color: "#FF9A3E",
  },
  bananna: {
    name: "Bananna",
    color: "#FCFF76",
  },
  bumblebee: {
    name: "Bumblebee",
    color: "#FFF500",
  },
  jade: {
    name: "Jade",
    color: "#00C853",
  },
  neon: {
    name: "Neon",
    color: "#8FFF00",
  },
  turqoise: {
    name: "Turqoise",
    color: "#00C8A4",
  },
  maya: {
    name: "Maya",
    color: "#00D1FF",
  },
  azure: {
    name: "Azure",
    color: "#0099FF",
  },
  steel: {
    name: "Steel",
    color: "#005D9A",
  },
  orchid: {
    name: "Orchid",
    color: "#8974EF",
  },
  electric: {
    name: "Electric",
    color: "#5639E5",
  },
  helio: {
    name: "Helio",
    color: "#CB5BFF",
  },
  violet: {
    name: "violet",
    color: "#8711BF",
  },
  cerise: {
    name: "Cerise",
    color: "#860068",
  },
  bubble_gum: {
    name: "Bubble Gum",
    color: "#BF1199",
  },
  rose_pink: {
    name: "Rose Pink",
    color: "#FF92E7",
  },
  asphalt: "#34495E",
  midnight: "#2C3E50",

  card_background: "#34495E",
  card_title_background: "#2C3E50",
} as const;

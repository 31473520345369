import { useGetLetterCategoryQuery } from "../../store/sisfotu/letterCategoryAPI";
import { BasicSelectOpt } from "../../types";
import { ACategoryLetter } from "../../types/endpoints/sisfotu/categoryLetter";

export interface ACategoryLetterOpt
  extends BasicSelectOpt<string>,
    Partial<ACategoryLetter> {}

interface ReturnVal {
  arr: ACategoryLetterOpt[];
  isLoading: boolean;
}

const useLetterCategoryOpts = (): ReturnVal => {
  const {
    data: CategoryLetterList,
    isLoading,
    isFetching,
  } = useGetLetterCategoryQuery({});

  return {
    isLoading: isLoading || isFetching,
    arr: CategoryLetterList
      ? CategoryLetterList?.map((category) => {
          return {
            label: category.code,
            value: category.code,
            ...category,
          };
        })
      : [],
  };
};

export default useLetterCategoryOpts;

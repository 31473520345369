/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { CircularProgress } from "@mui/material";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { createUseStyles } from "react-jss";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";

import { noImage_png } from "assets/images";
import { BasicTheme, COLORS, mq } from "assets/theme";
import { addAlphatoHexColor } from "assets/usefulFunctions";

import { ConfirmationModalPuspenerbal } from "components/Modal/ConfirmationModalPuspenerbal";
import LetterForm from "components/Modal/forms/letter/LetterForm";
import { StyledInputColContainer } from "components/Modal/sisfo/sisfo.style";
import { StyledButton } from "components/Modal/utils/modal.style";
import { useModal } from "components/Modal/utils/useModal";
import { PuspenerbalModal } from "components/molecules/PuspenerbalModal/PuspenerbalModal";
import PageContainer from "components/PageContainer";
import { StyledBackgroundContent } from "components/StyleComponent/StyleComponent";
import StyledToastBodyPuspenerbal from "components/Toast/StyledToastBodyPuspenerbal";

import {
  useDeleteLetterMutation,
  useGetLetterByIdQuery,
  useUpdateLetterMutation,
} from "store/sisfotu/letterStoreAPI/letterStoreAPI";
import { ALetterRequest } from "types/endpoints/sisfotu/letter";

import { SisfoTURoutes } from "config/nav";

type Props = {};

const useStyles = createUseStyles((_: BasicTheme) => {
  return {
    arrowBox: {
      width: "40px",
      minHeight: 40,
      fontSize: "2em",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      backgroundColor: COLORS.black_1_puspenerbal,
      color: COLORS.white,
      border: `1px solid ${COLORS.light_grey_3_puspenerbal}`,
    },
    bodyContentEmail: {
      color: COLORS.white,
      margin: "24px 2px",
      [mq[3]]: {
        margin: "24px 60px",
      },
    },
    headerContentEmail: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      flexDirection: "column",
      gap: 12,
      [mq[3]]: {
        alignItems: "center",
        flexDirection: "row",
      },
    },
  };
});

const DetailMailDraftPage = (_: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams<"id">();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const { isShown: isSend, toggle: toggleSend } = useModal();
  const { isShown: isEdit, toggle: toggleEdit } = useModal();
  const { isShown: isDelete, toggle: toggleDelete } = useModal();
  const [deleteLetter] = useDeleteLetterMutation();
  const [updateLetter] = useUpdateLetterMutation();
  const id = params.id!;

  const {
    data: letter,
    isLoading,
    isFetching,
  } = useGetLetterByIdQuery({ id: parseInt(id) }, { skip: !id });
  const loading = isLoading || isFetching;
  const handleSendLetter = React.useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      setIsSubmitting(true);
      if (!letter || !letter.id) return;

      //Will be used someday
      // const {
      //   // additionalInfo,
      //   // letterReceivers,
      //   // created_by,
      //   createdAt,
      //   updatedAt,
      //   ...rest
      // } = letter;

      const submitLetter: ALetterRequest = {
        // createdAt,
        // updatedAt,
        // receiver_nrp: letter.letterReceivers.map(
        //   (item) => item.user_detail.nrp,
        // ),
      };

      updateLetter({ id: letter.id, ...submitLetter })
        .unwrap()
        .then(() => {
          toggleSend();
          setIsSubmitting(false);
          if (letter.id) {
            navigate(SisfoTURoutes.getOutboxDetail(letter.id.toString()));
          }
          toast.success(
            <StyledToastBodyPuspenerbal
              headerText="Berhasil!"
              description={`Anda berhasil mengirim data Surat ${
                letter.isPublished ? "" : "Draft"
              } ${letter.letterNumber}.`}
            />,
          );
        })
        .catch((e) => {
          toggleSend();
          setIsSubmitting(false);
          toast.error(
            <StyledToastBodyPuspenerbal
              headerText="Gagal"
              description={
                <div>
                  Anda gagal mengirim data Surat{" "}
                  {letter.isPublished ? "" : "Draft"} {letter.letterNumber}.
                  <br />
                  {e.data.message}
                </div>
              }
            />,
          );
        });
    },
    [deleteLetter, toggleDelete, letter],
  );
  const handleDeleteItem = React.useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      setIsSubmitting(true);
      if (!letter || !letter.id) return;
      deleteLetter({ id: letter.id })
        .unwrap()
        .then(() => {
          toggleDelete();
          setIsSubmitting(false);
          navigate(-1);
          toast.success(
            <StyledToastBodyPuspenerbal
              headerText="Berhasil!"
              description={`Anda berhasil menghapus data Surat ${
                letter.isPublished ? "" : "Draft"
              } ${letter.letterNumber}.`}
            />,
          );
        })
        .catch((e) => {
          toggleDelete();
          setIsSubmitting(false);
          toast.error(
            <StyledToastBodyPuspenerbal
              headerText="Gagal"
              description={
                <div>
                  Anda gagal menghapus data Surat{" "}
                  {letter.isPublished ? "" : "Draft"} {letter.letterNumber}.
                  <br />
                  {e.data.message}
                </div>
              }
            />,
          );
        });
    },
    [deleteLetter, toggleDelete, letter],
  );
  return (
    <div style={{ display: "flex", minHeight: "100vh" }}>
      <PageContainer withControl>
        {loading ? (
          <CircularProgress
            size={24}
            color="inherit"
            style={{ color: COLORS.blue_1_puspenerbal }}
          />
        ) : (
          <>
            <ConfirmationModalPuspenerbal
              hide={toggleSend}
              onCancel={handleSendLetter}
              onConfirm={handleSendLetter}
              isShowConfirm={isSend}
              typeConfirmButton="button"
              type="confirm-send"
              headerText="Konfirmasi"
              isSubmitOnProgress={isSubmitting}
              confirmText="KIRIM"
              cancelText="SIMPAN DRAFT"
              message={`Apakah anda ingin mengirim data Surat ${
                letter?.isPublished ? "" : "Draft"
              } ${letter?.letterNumber}?`}
              isDeleteConfirm
            />
            <ConfirmationModalPuspenerbal
              hide={toggleDelete}
              onCancel={toggleDelete}
              onConfirm={handleDeleteItem}
              isShowConfirm={isDelete}
              typeConfirmButton="button"
              type="confirm-delete"
              headerText="Konfirmasi"
              isSubmitOnProgress={isSubmitting}
              confirmText="HAPUS"
              cancelText="BATALKAN"
              message={`Apakah anda ingin menghapus data Surat ${
                letter?.isPublished ? "" : "Draft"
              } ${letter?.letterNumber}?`}
              isDeleteConfirm
            />
            <PuspenerbalModal
              isShown={isEdit}
              hide={toggleEdit}
              headerText="Ubah Surat"
              showLineSeparator
              type="edit"
              modalContent={
                <LetterForm isEditing data={letter} hide={toggleEdit} />
              }
            />
            <Row>
              <StyledInputColContainer as={Col} style={{ flex: 0 }}>
                <div
                  className={classes.arrowBox}
                  aria-hidden="true"
                  onClick={() => navigate(-1)}
                  style={{ marginBottom: 16 }}
                >
                  <HiArrowNarrowLeft />
                </div>
              </StyledInputColContainer>
              <StyledInputColContainer as={Col} style={{ color: COLORS.white }}>
                <h2 style={{ marginBottom: 12, marginRight: 12 }}>
                  {letter?.letterNumber}
                </h2>
                <StyledButton
                  style={{
                    padding: "0px 12px",
                    height: 24,
                    borderRadius: 24,
                    marginBottom: 12,
                    marginRight: 4,
                    fontWeight: 200,
                  }}
                >
                  {letter?.code}
                </StyledButton>
              </StyledInputColContainer>
              <StyledInputColContainer
                as={Col}
                style={{
                  flex: 1,
                  color: COLORS.white,
                  flexDirection: "row-reverse",
                }}
              >
                <div style={{ marginBottom: 12 }}>
                  <>
                    <span
                      style={{
                        background: "transparent",
                        color: COLORS.red_1_puspenerbal,
                        fontWeight: 800,
                        fontSize: 12,
                        marginRight: 24,
                        cursor: "pointer",
                      }}
                      onClick={toggleDelete}
                    >
                      Hapus Draft
                    </span>
                    <span
                      style={{
                        background: "transparent",
                        color: COLORS.blue_1_puspenerbal,
                        fontWeight: 800,
                        fontSize: 12,
                        marginRight: 24,
                        cursor: "pointer",
                      }}
                      onClick={toggleEdit}
                    >
                      Ubah Draft
                    </span>
                    <StyledButton onClick={toggleSend}>
                      KIRIM SURAT
                    </StyledButton>
                  </>
                </div>
              </StyledInputColContainer>
            </Row>
            <StyledBackgroundContent>
              <div className={classes.headerContentEmail}>
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    gap: 12,
                  }}
                >
                  <img
                    src={noImage_png}
                    style={{ width: 48, height: 48, borderRadius: "50%" }}
                    alt=""
                  />
                  <div>
                    <h5 style={{ color: COLORS.white }}>
                      {letter?.fromUser.nrp}
                    </h5>
                    <span
                      style={{
                        color: addAlphatoHexColor(COLORS.white, 0.25),
                        fontWeight: 200,
                      }}
                    >
                      Kepada:{" "}
                    </span>
                  </div>
                </div>
                <div>
                  <h6 style={{ color: COLORS.white }}>
                    Status Surat :{" "}
                    <span
                      style={{
                        color:
                          letter?.status === "AKTIF"
                            ? COLORS.green_1_puspenerbal
                            : COLORS.yellow_puspenerbal_1,
                      }}
                    >
                      {letter?.status}
                    </span>
                  </h6>
                </div>
              </div>
              <div className={classes.bodyContentEmail}>
                <h3>No: {letter?.letterNumber}</h3>

                {letter?.code &&
                letter?.code.toLowerCase() === "surat perintah terbang" ? (
                  <>
                    <p>{letter?.textArea}</p>
                  </>
                ) : (
                  <p>{letter?.textArea}</p>
                )}
              </div>
            </StyledBackgroundContent>
          </>
        )}
      </PageContainer>
    </div>
  );
};

export default DetailMailDraftPage;

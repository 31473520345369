import React, {
  CSSProperties,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  ReactNode,
  useState,
} from "react";
import { Input, InputGroup, Label } from "reactstrap";
import { createUseStyles } from "react-jss";
import { InputType } from "reactstrap/types/lib/Input";
import { COLORS } from "../../assets/theme";
import {
  addAlphatoHexColor,
  backgroundVariantColorSwitch,
} from "../../assets/usefulFunctions";

interface InputHandle extends HTMLInputElement {
  focus: () => void;
}

export type InputVariant = "light" | "dark" | "success" | "warning" | "danger";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  disabled?: boolean;
  type?: InputType;
  value?: string | number;
  isError?: boolean;
  isFocus?: boolean;
  errorText?: string;
  helperText?: string;
  required?: boolean;
  variant?: InputVariant;
  containerInputStyle?: CSSProperties;
  inputGroupInnerStyle?: CSSProperties;
  icon?: ReactNode;
  isLabelVisible?: boolean;
}

export const useInputTextStyle = createUseStyles((props: InputProps) => {
  return {
    root: { marginBottom: "1.5rem" },
    input: {
      background:
        props.isError || props.variant === "danger"
          ? COLORS.gradient_red_error_puspenerbal
          : props.disabled
          ? COLORS.light_brown_1_puspenerbal
          : backgroundVariantColorSwitch(
              props.variant ?? "dark",
              props.disabled ?? false,
            ),
      border: props.isError ? `1px solid ${COLORS.red}` : "none",
      backdropFilter: "blur(5px)",
      color: COLORS.white,
      fontSize: "1.2em",
      borderRadius: 0,
      minHeight: "35px",
      "&::placeholder": {
        color: props.isError ? COLORS.white : COLORS.dark_grey_1,
        fontSize: "1em",
      },
      "&:-webkit-autofill": {
        "-webkit-text-fill-color": COLORS.white,
      },
      "&:disabled": {
        background:
          props.variant === "light"
            ? COLORS.light_brown_1_puspenerbal
            : props.variant === "success"
            ? addAlphatoHexColor(COLORS.green_1_puspenerbal, 0.3)
            : props.variant === "warning"
            ? addAlphatoHexColor(COLORS.yellow_puspenerbal_1, 0.3)
            : COLORS.light_brown_1_puspenerbal,
        cursor: "not-allowed",
      },
      "&:focus": {
        border: props.isError
          ? `1px solid ${COLORS.red}`
          : `1px solid ${COLORS.white}`,
        color: COLORS.white,
        boxShadow: "none",
        "&:-webkit-autofill": {
          "-webkit-text-fill-color": COLORS.white,
        },
        background: props.isError
          ? COLORS.gradient_red_error_puspenerbal
          : props.disabled
          ? COLORS.light_brown_1_puspenerbal
          : backgroundVariantColorSwitch(
              props.variant ?? "dark",
              props.disabled ?? false,
            ),
      },
    },
    inputWithGroup: {
      background: props.isError
        ? COLORS.gradient_red_error_puspenerbal
        : props.disabled
        ? COLORS.light_brown_1_puspenerbal
        : backgroundVariantColorSwitch(
            props.variant ?? "dark",
            props.disabled ?? false,
          ),
      display: "flex",
      alignItems: "center",
      border: props.isError ? `1px solid ${COLORS.red}` : "none",
      "&.focus": {
        border: `1px solid ${COLORS.white}`,
        background: props.isError
          ? COLORS.gradient_red_error_puspenerbal
          : backgroundVariantColorSwitch(
              props.variant ?? "dark",
              props.disabled ?? false,
            ),
      },
      backdropFilter: "blur(5px)",
      color: COLORS.white,
      borderRadius: 0,
      minHeight: 36,
      paddingLeft: "8px",
      paddingRight: "8px",
      "& input": {
        background: "transparent",
        border: "none",
        marginRight: "9px",
        padding: 0,
        boxShadow: "none",
        fontSize: "1.2em",
        color: COLORS.white,
        "&::placeholder": {
          color: props.isError ? COLORS.white : COLORS.dark_grey_1,
          fontSize: "1em",
        },
        "&:-webkit-autofill": {
          "-webkit-text-fill-color": COLORS.white,
        },
        "&:disabled": {
          background: "transparent",
          cursor: "not-allowed",
        },
        "&:focus": {
          border: "none",
          color: COLORS.white,
          boxShadow: "none",
          "&:-webkit-autofill": {
            "-webkit-text-fill-color": COLORS.white,
          },
          background: "transparent",
        },
      },
    },
    labelText: {
      color: props.isError
        ? "#FC5A5A"
        : props.variant === "light"
        ? COLORS.white
        : props.variant === "success"
        ? addAlphatoHexColor(COLORS.green_1_puspenerbal, 1)
        : props.variant === "warning"
        ? addAlphatoHexColor(COLORS.yellow_puspenerbal_1, 1)
        : props.variant === "danger"
        ? addAlphatoHexColor(COLORS.red_1_puspenerbal, 1)
        : COLORS.white,
      marginBottom: 8,
      textTransform: "uppercase",
      fontSize: 11,
    },
    helperText: {
      marginTop: 2,
      fontWeight: 400,
      color: COLORS.light_grey_2_puspenerbal,
      fontSize: 11,
    },
    errorText: {
      marginTop: 2,
      fontWeight: 400,
      color: COLORS.red_1_puspenerbal,
      fontSize: 9,
    },
  };
});

const InputForm: ForwardRefRenderFunction<InputHandle, InputProps> = (
  props,
  ref,
) => {
  const {
    name,
    type,
    label,
    disabled,
    style,
    value,
    onChange,
    errorText,
    helperText,
    isError = false,
    required,
    icon,
    variant,
    onFocus,
    onBlur,
    containerInputStyle,
    inputGroupInnerStyle,
    className,
    isLabelVisible = true,
    ...rest
  } = props;
  const classes = useInputTextStyle({ theme: props });
  const [isFocused, setIsFocused] = useState<boolean>(false);
  return (
    <div className={classes.root} style={containerInputStyle}>
      {label && (
        <Label
          style={{ visibility: isLabelVisible ? "visible" : "hidden" }}
          className={classes.labelText}
        >
          {label}
        </Label>
      )}
      {label && required && isLabelVisible ? (
        <span className={classes.errorText}>*</span>
      ) : (
        ""
      )}
      {icon ? (
        <InputGroup
          className={[
            classes.inputWithGroup,
            isFocused && !isError ? "focus" : "",
            className,
          ].join(" ")}
          style={{
            ...style,
          }}
        >
          <Input
            {...rest}
            style={{
              ...inputGroupInnerStyle,
            }}
            type={type}
            disabled={disabled}
            required={required}
            innerRef={ref}
            onFocus={(e) => {
              setIsFocused(!isFocused);
              onFocus && onFocus(e);
            }}
            onBlur={(e) => {
              setIsFocused(!isFocused);
              onBlur && onBlur(e);
            }}
            value={value}
            onChange={onChange}
          />
          {icon}
        </InputGroup>
      ) : (
        <Input
          {...rest}
          type={type}
          className={[classes.input, className].join(" ")}
          style={{
            ...style,
          }}
          disabled={disabled}
          required={required}
          innerRef={ref}
          value={value}
          onChange={onChange}
        />
      )}

      {isError ? (
        <p className={classes.errorText}>{errorText}</p>
      ) : helperText ? (
        <p className={classes.helperText}>{helperText}</p>
      ) : (
        ""
      )}
    </div>
  );
};

const InputText = React.forwardRef(InputForm);

export default InputText;
